import ProfileComponent from "../components/profileComponent";
import { useState, useEffect } from "react";
import Styles from '../css/teamPage.module.css'
import {NavLink} from "react-router-dom"
import { Helmet } from "react-helmet-async";
import { getAllDocs } from "../config";
// import allTeamImg from '../assets/images/allTeamImg.jpg'
import { secyArray } from "../data/teamData2425";
export default function Team2425 () {
  const [coordinator, setCoordinator] = useState([]);
  const getCoordinator = async () => {
    let data = await getAllDocs("team-Profile-2");
    if (data != null) {
      setCoordinator(data);
    }
  };
  const [overallCoordinators, setOverallCoordinators] = useState([]);
  const getOverallCoordinators = async () => {
    let data = await getAllDocs("team-Profile-1");
    if (data != null) {
      setOverallCoordinators(data);
    }
  };
  const [convenors, setConvenors] = useState([]);
  const getConvenors = async () => {
    let data = await getAllDocs("team-Profile-3");
    if (data != null) {
      setConvenors(data);
    }
  };

  useEffect(() => {
    getCoordinator();
    getOverallCoordinators();
    getConvenors();
  }, []);
  console.log(coordinator)
  return (
    <>
      <Helmet>
        <title>Our Team</title>
        <meta
          name="description"
          content="We're here to help! If you have any questions, please don't hesitate to reachout us. Our team is dedicated to providing you with the support you need."
        />
        <link rel="canonical" href="/team/team-24-25" />
      </Helmet>
      <div className={Styles.ourTeam}>
        <div className={Styles.banner}>
          {/* <img src={allTeamImg} alt="BioSoc All Team (2023-24)" /> */}
          <h1>Team (24-25)</h1>
        </div>
        <div className={Styles.allTeam}>
            <ul>
              <li><NavLink className={({isActive})=>(isActive?Styles.activeLink:Styles.notActiveLink)} to="/team/team-24-25"><button type="button"><p>Team (24-25)</p></button></NavLink></li>
              <li><NavLink className={({isActive})=>(isActive?Styles.activeLink:Styles.notActiveLink)} to="/team/team-23-24"><button type="button"><p>Team (23-24)</p></button></NavLink></li>
            </ul>
          </div>
        <div className={Styles.overallCoordinator}>
          <h2 style={{ textAlign: "center" }}>Overall Co-ordinator</h2>

          <div className={Styles.overallCordiProfile}>
          {
              overallCoordinators.map((profile) => {
                console.log(profile)
                const {image, name, post, linkedinId, emailId, phone } = profile.data;
                return (<>
                  <ProfileComponent
                  img={image}
                  name={name}
                  post={post}
                  linkedinId={linkedinId}
                  emailId={emailId}
                />
                </>)   
              })
            }
          </div>
        </div>
        <div className={Styles.coOrdinator}>
          <h2 style={{ textAlign: "center" }}>Co-ordinators</h2>
          <div className={Styles.cordiProfile}>
            {
              coordinator.map((profile) => {
                console.log(profile)
                const {image, name, post, linkedinId, emailId, phone } = profile.data;
                return (<>
                  <ProfileComponent
                  img={image}
                  name={name}
                  post={post}
                  linkedinId={linkedinId}
                  emailId={emailId}
                />
                </>)   
              })
            }
          </div>
        </div>

         <div className={Styles.secretary}>
          <h2 style={{ textAlign: "center" }}>Secretary</h2>
          <div className={Styles.secyProfile}>
            {
              secyArray.map((data) => {
                return (<>
                <ProfileComponent
                  img={data.img}
                  name={data.name}
                  post={data.post}
                  linkedinId={data.linkedinId}
                  emailId={data.emailId}
                />
                </>)    
              })
            }

          </div>
        </div>
      </div>
    </>
  );
}