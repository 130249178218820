// ProfOfferProject.jsx

import React, { useState } from "react";
import "../css/projectOfferings.css";

const ProjectOfferings = () => {
  const [profName, setProfName] = useState("");
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [estimatedDuration, setEstimatedDuration] = useState("");
  const [stipend, setStipend] = useState("");
  const [eligibility, setEligibility] = useState("");
  const [prereq, setPrereq] = useState("");

  const handleProjectSubmission = (e) => {
    e.preventDefault();
    // Handle project submission logic here
    console.log(
      "Project submitted:",
      profName,
      projectTitle,
      projectDescription,
      estimatedDuration,
      stipend
    );
    // Reset form fields after submission
    setProfName("");
    setProjectTitle("");
    setProjectDescription("");
    setEstimatedDuration("");
    setStipend("");
  };

  return (
    <div className="prof-offer-project-container">
      <h1 className="prof-offer-project-heading" style={{ color: "#015fb1" }}>
        Offer a Project
      </h1>

      <form
        className="prof-offer-project-form"
        onSubmit={handleProjectSubmission}
      >
        <label
          htmlFor="profName"
          className="form-label"
          style={{ color: "#015fb1" }}
        >
          Professor Name:
        </label>
        <input
          type="text"
          id="profName"
          value={profName}
          onChange={(e) => setProfName(e.target.value)}
          className="form-input"
          required
        />

        <label
          htmlFor="projectTitle"
          className="form-label"
          style={{ color: "#015fb1" }}
        >
          Project Title:
        </label>
        <input
          type="text"
          id="projectTitle"
          value={projectTitle}
          onChange={(e) => setProjectTitle(e.target.value)}
          className="form-input"
          required
        />

        <label
          htmlFor="projectDescription"
          className="form-label"
          style={{ color: "#015fb1" }}
        >
          Project Description:
        </label>
        <textarea
          id="projectDescription"
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
          className="form-textarea"
          required
        />

        <label
          htmlFor="eligibility"
          className="form-label"
          style={{ color: "#015fb1" }}
        >
          Eligibility:
        </label>
        <input
          type="text"
          id="eligibility"
          value={eligibility}
          onChange={(e) => setEligibility(e.target.value)}
          className="form-input"
          required
        />
        <label
          htmlFor="prereq"
          className="form-label"
          style={{ color: "#015fb1" }}
        >
          Pre requirements:
        </label>
        <input
          type="text"
          id="prereq"
          value={prereq}
          onChange={(e) => setPrereq(e.target.value)}
          className="form-input"
          required
        />
        <label
          htmlFor="estimatedDuration"
          className="form-label"
          style={{ color: "#015fb1" }}
        >
          Estimated Duration:
        </label>
        <input
          type="text"
          id="estimatedDuration"
          value={estimatedDuration}
          onChange={(e) => setEstimatedDuration(e.target.value)}
          className="form-input"
          required
        />

        <label
          htmlFor="stipend"
          className="form-label"
          style={{ color: "#015fb1" }}
        >
          Stipend:
        </label>
        <input
          type="text"
          id="stipend"
          value={stipend}
          onChange={(e) => setStipend(e.target.value)}
          className="form-input"
          required
        />

        <button type="submit" className="form-button">
          Submit Project
        </button>
      </form>
    </div>
  );
};

export default ProjectOfferings;
