import anshyFellowship from "../assets/studentAchievment/Ansys-Fellowship.jpg";
const studentAchievementsData = [
  {
    id: 1,
    image: anshyFellowship,
    achievementName: "Ansys Fellowship",
    achiever: "Aditi Bhattacharya & Aditi Bhattacharya ",
    shortDesc: `Ahana Sarkar and Aditi Bhattacharya selected as the awardees of the prestigious Ansys Fellowship. This initiative, a result of Ansys Software's Corporate Social Responsibility collaboration with
           IIT Kanpur, has proven to be a significant source of encouragement for the recipients`,
    link: "https://www.instagram.com/p/Cx0lkX7txHW/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA="
  },
  {
    id: 2,
    image: "https://pbs.twimg.com/media/Fy5sGFEaYAApcMN?format=jpg&name=large",
    achievementName: "Mehta Rice Engineering Scholar",
    achiever: "Tanay Biswas",
    shortDesc: `Tanay Biswas selected for the Mehta Rice 
Engineering Scholars Program, an initiative of The Bhupat & Jyoti Mehta Family Foundation & Rice University.`,
    link: "https://x.com/mfcem_iitk/status/1670391503792017408?s=20",
  },
  {
    id: 3,
    image:"https://pbs.twimg.com/media/F83lTXlboAAuPD4?format=jpg&name=large",
    achievementName: "Granted Indian Patent ",
    achiever: "Niranjan Chatterjee, Hariharan VC, Ravi Pandey",
    shortDesc:
      "Niranjan Chatterjee, Hariharan VC, Ravi Pandey Granted Indian Patent on:“A formulation for inhibition of SARS-COV2 infection and processes for preparation thereof”",
    link: "https://twitter.com/BSBEIITK1/status/1715279902830944635?t=U5mrbqEWcsM9y2mcuAc8uA&s=08",
  },
  {
    id: 4,
    image:"https://pbs.twimg.com/media/GAqumJDagAAgraJ?format=jpg&name=small",
    achievementName: "Best Poster Awards",
    achiever: " Zahra & Saptomee",
    shortDesc:
      "Zahra and Saptomee from Prof. Dhirendra Katti's lab received Best Poster awards at SBAOI conference!",
    link: "https://twitter.com/BSBEIITK1/status/1732389447499698572?t=rpgFDawWoB5UV6O8q1JqdA&s=08",
  },
  {
    id: 4,
    image:"https://pbs.twimg.com/media/GD9nSa9bMAEXAms?format=jpg&name=large",
    achievementName: "Best Poster Presentation Award",
    achiever: "Deepak M. Khushalani",
    shortDesc:
      "PhD student Deepak Khushalani and Dr. Nitin Mohan received the Poster Presentation Award at All India Cell Biology Conference held at TMC-ACTREC!",
    link: "https://twitter.com/BSBEIITK1/status/1747229954612629974/photo/1"
  },
  /*
  {
    id: 4,
    image:"",
    achievementName: "",
    achiever: "",
    shortDesc:
      "",
    link: ""
  },
  */
  // Add more achievements as needed
];
export default studentAchievementsData;
