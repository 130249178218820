import React from "react";
import styles from "../css/competitions.module.css";

function CompetitionCard(props) {
  return (
    <div className={styles.indvcard}>
      <p className={styles.compTitle}>{props.title}</p>
      <p className={styles.compDesc}>{props.desc}</p>
      <a className={styles.compLink} href={props.link}>
        Visit Website
      </a>
    </div>
  );
}

export default CompetitionCard;
