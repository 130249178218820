//
import styles from "../css/events.module.css";
import { useState, useEffect } from "react";
import { getAllDocs } from "../config";
import { Helmet } from "react-helmet-async";
import Banner from "../components/Banner";
//
const Events = () => {
  const [eventsBlockData, setEventsBlockData] = useState([]);
  const getEventsBlock = async () => {
    let data = await getAllDocs("events");
    if (data != null) {
      setEventsBlockData(data);
    }
  };
  useEffect(() => {
    getEventsBlock();
  }, []);

  return (
    <>
    <Helmet>
      <title>BioSoc | Events</title>
      <meta name="description" content="Join our bioengineering events with insightful talks, groundbreaking research, fun games, inspiring speeches, and vibrant performances. Experience our community spirit!" />
      <link rel="canonical" href="/events"/>
    </Helmet>
    <div>
      <Banner
      heading="Events" />
      <div className={styles.eventsContainer}>
        <div className={styles.eventOdd} id={eventsBlockData?.[0]?.id}>
          <img
            className={styles.imgOdd}
            src={eventsBlockData?.[0]?.data.img}
            alt="Event img"
          />
          <div className={styles.txt}>
            <h2>{eventsBlockData?.[0]?.data.title}</h2>
            <p>{eventsBlockData?.[0]?.data.desc}</p>
          </div>
        </div>
        <hr />

        <div className={styles.eventEven} id={eventsBlockData?.[1]?.id}>
          <img
            className={styles.imgEven}
            src={eventsBlockData?.[1]?.data.img}
            alt="Event img"
          />
          <div className={styles.txt}>
            <h2>{eventsBlockData?.[1]?.data.title}</h2>
            <p>{eventsBlockData?.[1]?.data.desc}</p>
          </div>
        </div>
        <hr />

        <div className={styles.eventOdd} id={eventsBlockData?.[2]?.id}>
          <img
            className={styles.imgOdd}
            src={eventsBlockData?.[2]?.data.img}
            alt="Event img"
          />
          <div className={styles.txt}>
            <h2>{eventsBlockData?.[2]?.data.title}</h2>
            <p>{eventsBlockData?.[2]?.data.desc}</p>
          </div>
        </div>
        <hr />
{/* 
        <div className={styles.eventEven} id={eventsBlockData?.[3]?.id}>
          <img
            className={styles.imgEven}
            src={eventsBlockData?.[3]?.data.img}
            alt="Event img"
          />
          <div className={styles.txt}>
            <h2>{eventsBlockData?.[3]?.data.title}</h2>
            <p>{eventsBlockData?.[3]?.data.desc}</p>
          </div>
        </div>
        <hr /> */}

        {/* <div className={styles.eventOdd} id={eventsBlockData?.[4]?.id}>
          <img
            className={styles.imgOdd}
            src={eventsBlockData?.[4]?.data.img}
            alt="Event img"
          />
          <div className={styles.txt}>
            <h2>{eventsBlockData?.[4]?.data.title}</h2>
            <p>{eventsBlockData?.[4]?.data.desc}</p>
          </div>
        </div>
      </div> */}

      {/* <div>
        <div style={{ height: "700px" }}></div>

        <div
          id="NfPQZNaOB0Qoow6xg5x9"
          style={{ backgroundColor: "blue", width: "100%", height: "200px" }}
        ></div>
        <div style={{ height: "700px" }}></div>

        <div
          id=""
          style={{ backgroundColor: "blue", width: "100%", height: "100px" }}
        ></div>
      </div> */}
    </div>
    </div>
    </>
  )
};

export default Events;
