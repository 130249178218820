// Gallery.jsx
import { useEffect, useState } from "react";
import React from "react";
import "../css/gallery.css";
import { getAllDocs } from "../config";
import Banner from "../components/Banner";
import GalleryCard from "../components/GalleryCard";
const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const getGallery = async () => {
    let data = await getAllDocs("gallery");
    if (data != null) {
      setGallery(data);
    }
  };
  useEffect(() => {
    getGallery();
  }, []);
  return (
    <>
    <Banner heading="Gallery of Excellence"/> 
    <div className="gallery-container">
      
      {/* <h1 className="gallery-heading" style={{ color: "#015fb1" }}>
      Gallery of Excellence
      </h1> */}

      <div className="gallery-grid">
        {gallery.map((gallery, index) => (
          <GalleryCard
            key={index}
            img={gallery.data.img}
            info = {gallery.data.info}
          />
       ))}
      </div>
    </div>
    </>
      );
};

      export default Gallery;
