import styles from "../css/navbar.module.css";
import { Link } from "react-router-dom";
import logo from "../assets/logo/logo1.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from "react";
import { FacultyMenu, StudentsMenu, CompaniesMenu, AlumniMenu } from "./MenuItems";
const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    forStudent: false,
    forCompanies: false,
    forFaculty: false,
    forAlumni: false,
  });
  function toggleDropdown(menu) {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  }
  // function closeDropdown(menu) {
  //   setDropdownOpen((prevState) => ({
  //     ...prevState,
  //     [menu]: !prevState[menu],
  //   }));
  // }
  console.log(StudentsMenu)
  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <Link to="/" className={styles.logoLink}>
          <img src={logo} className={styles.logo} alt="logo" />
          <h2>BioSoc <p>Society of BSBE, IIT Kanpur</p></h2>
        </Link>
      </div>
      <RxHamburgerMenu
        className={styles.hamburger}
        onClick={() => {
          let newStatus = !isExpanded;
          // console.log("new", newStatus);
          setIsExpanded(newStatus);
          setDropdownOpen(
            !dropdownOpen.forStudent &&
            !dropdownOpen.forCompanies &&
            !dropdownOpen.forFaculty &&
            !dropdownOpen.forAlumni
          );
        }}
      />

      <ul
        className={
          isExpanded === true
            ? `${styles.right} ${styles.expanded}`
            : styles.right
        }
      >
        <li>
          <Link
            className={styles.link}
            to="/"
            onClick={() => {
              setIsExpanded(false);
            }}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            className={styles.link}
            to="/aboutUs"
            onClick={() => {
              setIsExpanded(false);
            }}
          >
            About
          </Link>
        </li>
        <li
          className={styles.dropdown}
          onClick={() => toggleDropdown("forStudent")}
        >
          <Link className={styles.link}>
            For Students
            {dropdownOpen.forStudent ? <span>-</span> : <span>+</span>}
          </Link>
          <ul
            className={
              dropdownOpen.forStudent ? `${styles.show}` : `${styles.hide}`
            }
          >
            {
              StudentsMenu.map((smenu) => (
                <li key={smenu.path}>
                  <Link
                    className={styles.link}
                    to={smenu.path}
                    onClick={() => {
                      setIsExpanded(false);
                    }}
                  >
                    {smenu.title}
                  </Link>
                </li>
              ))
            }
          </ul>
        </li>
        <li
          className={styles.dropdown}
          onClick={() => toggleDropdown("forCompanies")}
        >
          <Link className={styles.link}>
            For Companies
            {dropdownOpen.forCompanies ? <span>-</span> : <span>+</span>}
          </Link>
          <ul
            className={
              dropdownOpen.forCompanies ? `${styles.show}` : `${styles.hide}`
            }
          >
            {
              CompaniesMenu.map((cmenu)=>(
                <li key={cmenu.path}>
              <Link
                className={styles.link}
                to={cmenu.path}
                onClick={() => {
                  setIsExpanded(false);
                }}
              >
                {cmenu.title}
              </Link>
            </li>
              ))
            }
          </ul>
        </li>
        <li
          className={styles.dropdown}
          onClick={() => toggleDropdown("forFaculty")}
        >
          <Link className={styles.link}>
            About Faculty
            {dropdownOpen.forFaculty ? <span>-</span> : <span>+</span>}
          </Link>
          <ul
            className={
              dropdownOpen.forFaculty ? `${styles.show}` : `${styles.hide}`
            }
          >
                        {
              FacultyMenu.map((fmenu) => (
                <li key={fmenu.path}>
                  <Link
                    className={styles.link}
                    to={fmenu.path}
                    onClick={() => {
                      setIsExpanded(false);
                    }}
                  >
                    {fmenu.title}
                  </Link>
                </li>
              ))
            }
          </ul>
        </li>
        <li
          className={styles.dropdown}
          onClick={() => toggleDropdown("forAlumni")}
        >
          <Link className={styles.link}>
            For Alumni
            {dropdownOpen.forAlumni ? <span>-</span> : <span>+</span>}
          </Link>
          <ul
            className={
              dropdownOpen.forAlumni ? `${styles.show}` : `${styles.hide}`
            }
          >
                        {
              AlumniMenu.map((amenu) => (
                <li key={amenu.path}>
                  <Link
                    className={styles.link}
                    to={amenu.path}
                    onClick={() => {
                      setIsExpanded(false);
                    }}
                  >
                    {amenu.title}
                  </Link>
                </li>
              ))
            }
          </ul>
        </li>
        <li>
          <Link
            className={styles.link}
            to="/team/team-24-25"
            onClick={() => {
              setIsExpanded(false);
            }}
          >
            Team
          </Link>
        </li>
        <li>
          <Link
            className={styles.link}
            to="/contactus"
            onClick={() => {
              setIsExpanded(false);
            }}
          >
            Contact
          </Link>
        </li>
        {/* <li>
          <Link
            className={styles.link}
            to="/login"
            onClick={() => {
              setIsExpanded(false);
            }}
          >
            Login
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Navbar;
