import styles from "../css/footer.module.css";
import logo from "../assets/logo/logo1.png";
import {
  AiOutlineInstagram,
  AiFillLinkedin,
  AiOutlineTwitter,
  AiOutlineMail,
  AiOutlineYoutube,
} from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { Button } from "./";
import { useState } from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  const [data, setData] = useState({
    email: "",
  });
  function handleChange(event) {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: [value],
    }));
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = data;
    if (email) {
      const res = await fetch(
        "https://biosoc-ae06d-default-rtdb.asia-southeast1.firebasedatabase.app/newsLetterFormData.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
        }
      );
      if (res) {
        setData({
          email: "",
        });
        alert("Thankyou For Subscribing Newsletter!");
      } else {
        alert("Error! Try Again");
      }
    }
  };
  console.log(data);
  return (
    <div className={`noSelect ${styles.footer}`}>
      <div className={styles.list}>
        <div className={styles.left}>
          <div>
            {" "}
            <img src={logo} />
          </div>
          <div className={styles.address}>
            BioSoc
            <br /> Department of Biological Sciences & Bioengineering
            <br />
            Indian Institute of Technology, Kanpur
            <br /> Kanpur, 208016
            <div style={{ display: "flex", alignItems: "center" }}>
              <HiOutlineMail size={20} />
              &nbsp;
              <a href="mailto:biosoc@iitk.ac.in">biosoc@iitk.ac.in</a>
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <h4 style={{ color: "var(--color5)" }}>QUICK LINKS</h4>
          <ul>
            <li>
              <a href="https://www.iitk.ac.in/bsbe/" target="_blank">
                BSBE IIT Kanpur
              </a>
            </li>
            <li>
              <a href="https://www.iitk.ac.in/" target="_blank">
                IIT Kanpur
              </a>
            </li>
            <li>
              <Link to="/engage-with-us">Engage With Us</Link>
            </li>
            <li>
              <Link to="/our-students">Our Students</Link>
            </li>
            <li>
              <Link to="/projects">Ongoing Projects</Link>
            </li>
            <li>
              <a href="https://spo.iitk.ac.in/" target="_blank">
                Placement Procedure
              </a>
            </li>
            <li>
              <a href="http://www.iitk.ac.in/counsel/" target="_blank">
                Counselling Services
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.right}>
          <h4 style={{ color: "white" }}>
            LET'S CONNECT WITH BIOSOC TO GET UPDATES
          </h4>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "70%",
                marginRight: "5px",
              }}
            >
              <HiOutlineMail
                color="var(--color4)"
                style={{
                  position: "absolute",
                  zIndex: 2,
                  left: "5px",
                  top: "5px",
                }}
                size={25}
              />
              <input
                type="email"
                placeholder="email"
                name="email"
                id="email"
                required
                value={data.email}
                onChange={handleChange}
              ></input>
            </div>
            <Button text="Subscribe" onClick={handleSubmit} />
          </div>
          <div className={styles.social}>
            <a
              href="https://www.linkedin.com/company/biosociitk/"
              target="_blank"
            >
              <AiFillLinkedin size={30} color="var(--color5)" />
            </a>
            <a
              href="https://youtube.com/@bsbeiitk2925?si=ayvarVDuKRvKrfSn"
              target="_blank"
            >
              <AiOutlineYoutube size={30} color="var(--color5)" />
            </a>
            <a href="mailto:biosoc@iitk.ac.in" target="_blank">
              <AiOutlineMail size={30} color="var(--color5)" />
            </a>
            <a href="https://www.instagram.com/biosoc_iitk/" target="_blank">
              <AiOutlineInstagram size={30} color="var(--color5)" />
            </a>
            <a href="https://x.com/BSBEIITK1" target="_blank">
              <AiOutlineTwitter size={30} color="var(--color5)" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
