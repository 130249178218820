import Styles from "../css/blog.module.css";
import PostHeader from "../components/PostHeader";
import FooterNavigator from "../components/FooterNavigator";

const Blog = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.background}>
        <PostHeader />
        <div className={Styles.content}>blog content</div>
        <FooterNavigator 
         back="Back"
         next ="Next"
        />
      </div>
    </div>
  );
};

export default Blog;
