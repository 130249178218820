import Styles from "../css/profilecard.module.css";
import { ProfileCard } from "../components/index";
import { useState, useEffect } from "react";
import { getAllDocs } from "../config";
import { Helmet } from "react-helmet-async";

const Team = () => {
  const [coordinator, setCoordinator] = useState([]);
  const getCoordinator = async () => {
    let data = await getAllDocs("team-Profile-1");
    if (data != null) {
      setCoordinator(data);
    }
  };
  const [overallCoordinators, setOverallCoordinators] = useState([]);
  const getOverallCoordinators = async () => {
    let data = await getAllDocs("team-Profile-2");
    if (data != null) {
      setOverallCoordinators(data);
    }
  };
  const [convenors, setConvenors] = useState([]);
  const getConvenors = async () => {
    let data = await getAllDocs("team-Profile-3");
    if (data != null) {
      setConvenors(data);
    }
  };

  useEffect(() => {
    getCoordinator();
    getOverallCoordinators();
    getConvenors();
  }, []);

  return (
    <>
      <Helmet>
        <title>Our Team</title>
        <meta
          name="description"
          content="We're here to help! If you have any questions, please don't hesitate to contact us. Our team is dedicated to providing you with the support you need."
        />
        <link rel="canonical" href="/team" />
        {/* <meta name="robots" content="noindex" />  Disallowing Google to crawl from our end: but does not guarantee */}
      </Helmet>
      <div className={Styles.background}>
        <h1 className={Styles.head}>Our Team @BioSoc</h1>
        <h2 className={Styles.titles}>Faculty Convenors:</h2>
        <div className={Styles.cards}>
          {coordinator.map((profile) => (
            <ProfileCard key={profile.id} profile={profile} />
          ))}
        </div>
        <h2 className={Styles.titles}>Overall Coordinators:</h2>
        <div className={Styles.cards}>
          {overallCoordinators.map((profile) => (
            <ProfileCard key={profile.id} profile={profile} />
          ))}
        </div>
        <h2 className={Styles.titles}>Coordinators:</h2>
        <div className={Styles.cards}>
          {convenors.map((profile) => (
            <ProfileCard key={profile.id} profile={profile} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Team;
