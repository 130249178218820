// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBzlKj_OH2NcQN48bitUwzv2WqUy_c1Bg8",
  authDomain: "biosoc-ae06d.firebaseapp.com",
  databaseURL: "https://biosoc-ae06d-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "biosoc-ae06d",
  storageBucket: "biosoc-ae06d.appspot.com",
  messagingSenderId: "55566451032",
  appId: "1:55566451032:web:e19ac6410d70fef9e4252d"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
