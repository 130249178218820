// Labs.jsx

import React, { useState } from "react";
import "../css/labs.css";
import developmentalNeurobiologyImg from "../assets/images/developmentalNeurobiology.jpg";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Helmet } from "react-helmet-async";
import Banner from "../components/Banner";
const Labs = () => {
  const [labdesc, setLabDesc] = useState({
    lab1:false,
    lab2:false,
    lab3:false,
    lab4:false,
    lab5:false,
    lab6:false,
  });
  function handelView (menu){
    setLabDesc((prevState)=>({
        ...prevState,
        [menu]: !prevState[menu]
      }))
  }
  // Dummy lab data
  const labsData = [
    {
      lab:"lab1",
      name: "Developmental Neurobiology Lab",
      image: developmentalNeurobiologyImg,
      link: "https://sites.google.com/view/jslab-iitk/home",
      pi: "Dr. Jonaki Sen",
      description: "Studying the development of the nervous system.",
    },
    {
      lab:"lab2",
      name: "Skeletal Biology Lab",
      image: developmentalNeurobiologyImg,
      link: "https://sites.google.com/view/ablab-iitk/home",
      pi: "Dr. Amitabha Bandyopadhyay",
      description: "Exploring the biology of skeletal structures.",
    },
    {
      lab:"lab3",
      name: "Fly Lab",
      image: developmentalNeurobiologyImg,
      link: "http://www.pradipslab.com",
      pi: "Dr. Pradip Kumar",
      description: "Investigating genetic aspects using fruit flies.",
    },
    {
      lab:"lab4",
      name: "GPCR Biology Lab",
      image: developmentalNeurobiologyImg,
      link: "https://sites.google.com/site/laboratoryofgpcrbiology/",
      pi: "Dr. Arun Kumar Shukla",
      description: "Researching G protein-coupled receptors.",
    },
    {
      lab:"lab5",
      name: "Signalling Lab",
      image: developmentalNeurobiologyImg,
      link: "https://www.mmcsliitk.in/",
      pi: "Dr. Sai Prasad Pydi",
      description: "Studying cellular signaling pathways.",
    },
    {
      lab:"lab6",
      name: "Decision Lab",
      image: developmentalNeurobiologyImg,
      link: "https://sites.google.com/view/decisionlabiitk/home?authuser=0",
      pi: "Dr. Arjun Ramakrishnan",
      description: "Investigating decision-making processes.",
    },

    // Add more labs as needed
  ];

  return (
    <>
    <Helmet>
      <title>BioSoc IITK | Labs</title>
      <meta name="description" content="Explore the cutting-edge research labs at IIT Kanpur. Learn about the Developmental Neurobiology Lab, Skeletal Biology Lab, Fly Lab, GPCR Biology Lab, Signalling Lab, and Decision Lab. Discover groundbreaking research in areas like nervous system development, skeletal biology, genetics, GPCR biology, cellular signaling, and decision-making processes."/>
      <link rel="canonical" href="/labs" />
    </Helmet>
    <>
    <Banner heading="Laboratories of Innovation"/>
    <div className="labs-container">
      <div className="labs-grid">
        {labsData.map((lab, index) => (
          <div key={index} className="lab-item">
            <div className="lab-left">
              <div className="head">
                <div className="lab-image">
                  <img src={lab.image} alt={`Lab ${index + 1}`} />
                </div>

                <div className="lab-details">
                  <h2 className="lab-name" style={{ color: "#015fb1" }}>
                    {lab.name}
                  </h2>
                  <div>
                  <div className="pi-name">
                    <p style={{ color: "rgb(83, 83, 83)" }}>PI: {lab.pi}</p>
                    <span 
                    onClick={()=>handelView(`${lab.lab}`)}
                    >{
                      labdesc[lab.lab] ?
                      <IoIosArrowDropleftCircle 
                      title="Close Description"
                      style={{height:"25px", width: "25px"}} 
                      /> :
                      <IoIosArrowDroprightCircle
                      title="Show Description"
                      style={{height:"25px", width: "25px"}}
                      />
                    }
                    </span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lab-right-desc">
              {labdesc[lab.lab] && 
                <div className="lab-desc">
                  <p
                    className="lab-desc-detail"
                    style={{ color: "rgb(83, 83, 83)" }}
                  >
                    {lab.description}
                  </p>
                  <a
                    href={lab.link}
                    target="_blank"
                    style={{ color: "rgb(83, 83, 83)" }}
                  >
                    visit lab webpage
                  </a>
                </div>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
    </>
  );
};

export default Labs;
