// AlumniNetworkPage.jsx

import React from "react";
import "../css/alumniNetwork.css"; // Import the corresponding CSS file

const AlumniNetworkPage = () => {
  // Dummy data for core and non-core alumni
  const coreAlumniData = [
    {
      id: 1,
      image: "alumni_image_1.jpg",
      name: "Jane Doe",
      batch: "2010",
      contact: "jane.doe@example.com",
    },
    {
      id: 2,
      image: "alumni_image_2.jpg",
      name: "John Smith",
      batch: "2012",
      contact: "john.smith@example.com",
    },
    // Add more core alumni as needed
  ];

  const nonCoreAlumniData = [
    {
      id: 3,
      image: "alumni_image_3.jpg",
      name: "Alice Johnson",
      batch: "2015",
      contact: "alice.johnson@example.com",
    },
    {
      id: 4,
      image: "alumni_image_4.jpg",
      name: "Bob Miller",
      batch: "2017",
      contact: "bob.miller@example.com",
    },
    // Add more non-core alumni as needed
  ];

  return (
    <div className="alumni-network-page">
      <h1 style={{ color: "#015fb1" }}>Alumni Network</h1>

      <div className="core-alumni-container">
        <h2 className="sub-heading" style={{ color: "#4d954c" }}>
          Core Alumni
        </h2>
        <div className="alumni-cards">
          {coreAlumniData.map((alumni) => (
            <div key={alumni.id} className="alumni-card">
              <img src={alumni.image} alt={alumni.name} />
              <h3>{alumni.name}</h3>
              <p>Batch: {alumni.batch}</p>
              <p>Contact: {alumni.contact}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="non-core-alumni-container">
        <h2 className="sub-heading" style={{ color: "#4d954c" }}>
          Non-Core Alumni
        </h2>
        <div className="alumni-cards">
          {nonCoreAlumniData.map((alumni) => (
            <div key={alumni.id} className="alumni-card">
              <img src={alumni.image} alt={alumni.name} />
              <h3>{alumni.name}</h3>
              <p>Batch: {alumni.batch}</p>
              <p>Contact: {alumni.contact}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlumniNetworkPage;
