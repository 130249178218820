import Styles from "../css/postHeader.module.css"

const PostHeader = () => {
  return (
    <div>
      <h1 className={Styles.title}>Blog Title</h1>
      <div className={Styles.detail}>
        <p>27 July 2024</p>
        <span>|</span>
        <p>5 Min Read</p>
        <span>|</span>
        <p>by Writer</p>
      </div>
    </div>
  );
};
export default PostHeader;