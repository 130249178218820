import profileimg from "../assets/logo/profileimg.jpg";
import gauri from  '../assets/team2324/ocoordi/Gauri-OC.jpg'
import tanuja from  '../assets/team2324/coordi/Tanuja-Coordinator.jpg'
import advaith from  '../assets/team2324/coordi/Advaith-Coordinator.jpg'
import deepak from  '../assets/team2324/secy/DEEPAL-GAUTAM-SECY.jpg'
import anuj from  '../assets/team2324/secy/ANUJ-SECY.jpg'
import asif from  '../assets/team2324/secy/Asif-nawaz-secy.jpg'
import eeshwari from  '../assets/team2324/secy/EESHWARI-SUNKERSETT-SECY.jpg'
import karan from  '../assets/team2324/secy/Karan-Jangid-Secy.jpg'
import pradeep from  '../assets/team2324/secy/Pradeep-Kumar-Secy.jpeg'
import kritnandan from  '../assets/team2324/secy/kritnandan-Secy.jpg'
import nishay from  '../assets/team2324/secy/Nischay-Secy.jpg.jpg'

export const ocArray = [
  {
    image: gauri,
    name: "Gauri",
    linkedinId: "https://www.linkedin.com/in/gauri-g-menon/",
    emailId: "mailto:emailid@gmail.com",
    post: "Overall Co-ordinator",
  },
  {
    image: profileimg,
    name: "Pratyusha",
    linkedinId: "https://www.linkedin.com/in/id/",
    emailId: "mailto:emailid@gmail.com",
    post: "Overall Co-ordinator",
  },
];
export const cArray = [
  {
    img: profileimg,
    name: "Amrit",
    post: "Web Development",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: tanuja,
    name: "Tanuja Kaleli",
    post: "Research and Projects",
    linkedinId: "https://www.linkedin.com/in/tanuja-k-220156225/",
    emailId: "#",
  },
  {
    img: advaith,
    name: "Advaith Kannan",
    post: "Marketing and Fundraising",
    linkedinId: "https://www.linkedin.com/in/advaith-kannan/",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Deepak",
    post: "Social Media",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Abhishek Kumar",
    post: "Events",
    linkedinId: "#",
    emailId: "#",
  },
];
export const secyArray = [
  {
    img: profileimg,
    name: "Manasvi Nidugala",
    post: "Marketing",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: eeshwari,
    name: "Eeshwari Sunkersett",
    post: "Marketting ",
    linkedinId: "https://www.linkedin.com/in/eeshwari-sunkersett-766373250/",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Suryansh Dwivedi",
    post: "Marketing",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: asif,
    name: "Asif nawaz ",
    post: "Marketing",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Sneh",
    post: "Marketing",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Vanshika Yadav",
    post: "Marketing",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: pradeep,
    name: "Pradeep Kumar",
    post: "Web Dev",
    linkedinId: "https://www.linkedin.com/in/pradeepkumar-es/",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Karan Jangid",
    post: "Web Dev",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: karan,
    name: "Bhavya Garg",
    post: "Web Dev",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Lakshit Sharma",
    post: "Research",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Debarpita Dash",
    post: "Research",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Adhiraj Gupta",
    post: "Research ",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Aryan Mittal ",
    post: "Research ",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Yeleena Vimal",
    post: "Research ",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Jonnala Hema Phani Sri",
    post: "Social Media",
    linkedinId: "https://www.linkedin.com/in/hema-phani-sri-jonnala-181876265/",
    emailId: "#",
  },
  {
    img: deepak,
    name: "Deepak Gautam",
    post: "Social Media",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Aradhya Gautam",
    post: "Social Media",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Krrish khandelwal",
    post: "Social Media",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "ADRISH CHAKRABARTI",
    post: "Events",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: nishay,
    name: "Nischay Patel",
    post: "Events",
    linkedinId: "https://www.linkedin.com/in/nischaypatel/",
    emailId: "#",
  },
  {
    img: anuj,
    name: "Anuj Gargya",
    post: "Events",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Aarsh Choudhari ",
    post: "Events",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Rajavardhan Ramavath",
    post: "Events",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Keshav Khandelwal",
    post: "Events",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: kritnandan,
    name: "Kritnandan",
    post: "Events",
    linkedinId: "#",
    emailId: "#",
  },
  {
    img: profileimg,
    name: "Hariom Singh",
    post: "Events",
    linkedinId: "#",
    emailId: "#",
  },
];
