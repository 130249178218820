import React from 'react'
import styles from "../css/Banner.module.css"
const Banner = (props) => {
  return (
    <div className={styles.banner}>
        <h1>{props.heading}</h1>
        <h3>{props.description}</h3>
      </div>
  )
}

export default Banner
