// AchievementsPage.jsx

import React from "react";
import styles from "../css/stdAchievements.module.css";
import achievementsData from "../data/studentAchievementsData";
import { Helmet } from "react-helmet-async";

const StudentAchievements = () => {
  return (
    <>
      <Helmet>
        <title>Our Achievements</title>
        <meta
          name="description"
          content="This Page is dedicate to highlights the our department student achievements"
        />
        <link rel="canonical" href="/student-achievements" />
      </Helmet>
      <div className={styles.achievementsPage}>
        <h1 style={{ color: "#015fb1" }}>Student Achievements</h1>
        {/* <div className={styles.allContainer}> */}
            <div className={styles.mainContentCard}>
              {achievementsData.map((achievement) => (
                <div key={achievement.id} className={styles.card}>
                  <div className={styles.achievementImg}>
                    <a
                      href={achievement.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={achievement.image}
                        alt={achievement.achievementName}
                      />
                    </a>
                  </div>
                  <div className={styles.detail}>
                    <h2>{achievement.achievementName}</h2>
                    <p>{achievement.shortDesc}</p>
                    <hr className={styles.hrline} />
                    <h4>
                      <span>Recipient: </span> {achievement.achiever}
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          <div className={styles.containerRigth}>
            <div>
              <p>Recruit Talent</p>
            </div>
          </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default StudentAchievements;
