import React from "react";
import Styles from "../css/profilecard.module.css";
import { TfiEmail } from "react-icons/tfi";
import { GrLinkedin } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";

function ProfileCard(props) {
  const { image, name, post, phone, email, linkedin } = props.profile.data;
  // console.log(props);
  return (
    <div className={Styles.cardcontainer}>
      <header>
        <img className={Styles.profileimg} src={image} alt={name} />
      </header>
      <h1 className={Styles.boldtext1}>
        {name}
        <br /> <span className={Styles.normaltext}>{post}</span>
      </h1>
      <div className={Styles.socialcontainer}>
        <a href={phone} className={Styles.icon}>
          <FiPhoneCall size={20} className={Styles.icon1} />
        </a>
        <a href={email} className={Styles.icon}>
          <TfiEmail size={20} className={Styles.icon1} />
        </a>
        <a href={linkedin} className={Styles.icon}>
          <GrLinkedin size={20} className={Styles.icon1} />
        </a>
      </div>
    </div>
  );
}

export default ProfileCard;
