import React from "react";
import styles from "../css/projectcard.module.css";
import project from "../assets/projectcard/project.jpg";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";

const style = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
};

function ProjectCard(props) {
  const { criteria, desc, dueDate, duration, img, offeredBy, proposal, title, uDate } = props.project.data;
  console.log(props);
  return (
    <div className={styles.indvcard}>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia sx={{ height: 140 }} image={img} title="project" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {desc}
          </Typography>
          <List sx={style} component="nav" aria-label="mailbox folders">
            <ListItem button>
              <ListItemText
                primary="Offered By"
                secondary={offeredBy}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary="Eligibility Criteria"
                secondary={criteria}
              />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Duration" secondary={duration} />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Uploaded Date" secondary={uDate} />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Due Date" secondary={dueDate} />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Proposal" />
              <a style={{ color: "blue", fontSize: "12px" }} href={proposal}>
                Download Proposal
              </a>
            </ListItem>
          </List>
        </CardContent>
        <CardActions>
          <Button size="small" variant="contained">
            <a className={styles.applybutton} href="www.google.com">
              Apply Now
            </a>
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

export default ProjectCard;
