import Styles from "../css/PostFooter.module.css"
const FooterNavigator = (props) => {
  return (
    <div>
      <div className={Styles.buttonContainer}>
        <button className={Styles.btn}>{props.back}</button>
        <button className={Styles.btn}>{props.next}</button>
      </div>
    </div>
  );
};
export default FooterNavigator;
