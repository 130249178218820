import { useState } from "react";
import { Link } from "react-router-dom";
import {
  StudentsMenu,
  CompaniesMenu,
  FacultyMenu,
  AlumniMenu,
} from "./MenuItems";
import "../css/Dropdown.css";

function Dropdown1() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {StudentsMenu.map((item, i) => {
          return (
            <li key={i + 1}>
              <Link to={item.path} onClick={() => setClick(false)}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function Dropdown2() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {CompaniesMenu.map((item, i) => {
          return (
            <li key={i + 1}>
              <Link to={item.path} onClick={() => setClick(false)}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function Dropdown3() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {FacultyMenu.map((item, i) => {
          return (
            <li key={i + 1}>
              <Link to={item.path} onClick={() => setClick(false)}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function Dropdown4() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {AlumniMenu.map((item, i) => {
          return (
            <li key={i + 1}>
              <Link to={item.path} onClick={() => setClick(false)}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export { Dropdown1, Dropdown2, Dropdown3, Dropdown4 };
