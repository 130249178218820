import React from 'react'
import styles from "../css/ScholarshipsBasicsite.module.css";

const Scholarships1 = () => {

  return (
    <>
      <div className={styles.Heading}>
        Unlock Your Potential With Scholarships
      </div>
      <div className={styles.img}>
        <img src={ require('../assets/images/bsbeBuilding.jpg') }  />
      </div>

      <div className={styles.container}> 
        <div className={styles.logo}>
          <img src={ require("../assets/logo/logo1.png") } />
        </div>
        <div className={styles.title}>
            KVPY Kishore Vigyanik Protshan Yogana
        </div>
      </div>

      <div className={styles.about}>
        <h2>About The</h2> <span>Program</span>
      </div>
      <p className={styles.description}>
      <strong>Funded by the Department of Science and Technology of the Government of India</strong>, KVPY is a renowned scholarship exam organised to shortlist
      candidates with a strong scientific temperament and are<strong> willing to pursue Basic Science courses </strong>/are pursuing/ want to build a career in
      Research. Candidates belonging to class 11th, 12th and 1st year of the undergraduate programs stand eligible to appear for the
      examination. Majorly, there are 3 streams of the KVPY exam namely, SA (class 11th), SX (class 12th), and SB (for the undergraduate
      program).
      </p>
      <div className={styles.elig}>Eligibilty</div>

      <div className={styles.points}>
        <ul>
          <li>All Students Studying in Class 11 and 12 standard</li>
          <li>All first year Ug Students enrolled in Pure Sciences</li>
        </ul>
      </div>

      <div className={styles.elig}>Benifits</div>
      <div className={styles.points}>
        <ul>
          <li>5000 to 7000 Rupees per month</li>
          <li>20000-28000 Contingency Aid/per Year</li>
          <li>The scholarship money will be provided to you up until pre-PhD level</li>
          <li>SA stream students get the benefit of traveling to National Science Camp</li>
          <li>students are invited to participate in the summer program related to research and technology</li>
          <li>Access to library and laboratory facilities at national universities</li>
        </ul>
      </div> 

      <div className={styles.elig}>How Can You Apply?</div>
      <div className={styles.points}>
        <ul>
          <li>Vist the KVPY Website for more details</li>
        </ul>
      </div>
    </>
  );

};

export default Scholarships1;
