export const InterNationalCompData =[
    {
        title:"International Bio-Molecular Design Competition (BioMod):",
        desc:"BioMod is an international competition that focuses on the design of functional biomolecules and biomolecular systems. Undergraduate teams participate in designing and simulating novel biomolecules and presenting their work at the annual Jamboree in march",
        link:"http://biomod.net/",
      },
      {
        title:"Biodesign Challenge",
        desc:"Join an international competition and education program for high schools and colleges that introduces students to the intersections of biotechnology, art, and design. By registering for the program, your classroom gains access to our resources and mentor network.  After the Challenge, everyone who participated in a BDC course, not just finalists, becomes a part of the growing biodesign alumni community and receives support for future opportunities.",
        link:"https://www.biodesignchallenge.org/join-bdc",
      },
      {
        title:"Global Undergraduate Awards (GUA)",
        desc:"  The Global Undergraduate Awards is the world’s leading undergraduate awards programme which recognises top undergraduate work, shares this work with a global audience and connects students across cultures and disciplines.",
        link:"https://predictioncenter.org/casp13/",
      },
      {
        title:"Global Health Case Competition: ",
        desc:"Several universities and organizations host global health case competitions where undergraduate students form teams and develop innovative solutions to real-world global health challenges. These competitions often focus on interdisciplinary collaboration and addressing health disparities.",
        link:"https://globalhealthcenter.umn.edu/global-health-case-competition",
      },
      {
        title:"International Synthetic Biology Competition (iGem Undergraduate Track):",
        desc:" While iGEM primarily focuses on high school and graduate teams, it also offers an undergraduate track for teams composed entirely of undergraduate students. Participants engage in synthetic biology projects and present their work at the iGEM Giant Jamboree.",
        link:"https://competition.igem.org/",
      },
      {
        title:"MIT Synthetic Biology Competition:",
        desc:"MIT Synthetic Biology Competition: This competition, organized by the Massachusetts Institute of Technology (MIT), focuses on synthetic biology projects. It welcomes both undergraduate and postgraduate teams to showcase their innovative research and applications of synthetic biology.",
        link:"mit-igem.github.io"
      },
      {
        title:"CASP",
        desc:"CASP, which stands for Critical Assessment of Techniques for Protein Structure Prediction, is a community-wide experiment (though it is commonly referred to as a competition) for protein structure prediction taking place every two years since 1994.",
        link:"https://predictioncenter.org/casp13/",
      }
    ]
    // <h2 className:{Styles.titles}>National Competitions</h2>
export const NationalCompData=[
      {
        title:"Biotathlon",
        desc:`Organized by the Indian Institute of Technology (IIT) Bombay, Biotathlon is a national-level biotechnology competition,
        that tests participants' knowledge and skills through various rounds, including quizzes, case studies, and presentations.`,
        link:"/",
      },
      {
        title:"Biotechnology Innovation Challenge (BINC)",
        desc:`Conducted by the Department of Biotechnology (DBT), BINC is a national-level examination that assesses the  ,
        knowledge and aptitude of postgraduate students in biotechnology. It serves as a qualification for junior
        research fellowships in the field.`,
        link:"/",
      },
      {
        title:"BBiotechnology Ignition Grant (BIG) Scheme",
        desc:`Run by the Department of Biotechnology (DBT), Government of India, BIG is a grant program that supports 
        early-stage biotech startups and innovative projects. It provides financial assistance and mentorship opportunities.`,
        link:"/",
      },
      {
        title:"National Science and Technology Entrepreneurship Development Board (NSTEDB) Student Startup-of-the-Year Award",
        desc:`This award recognizes outstanding student-led startups in the field of biotechnology and life sciences.
        It celebrates innovation, commercial potential, and societal impact.`,
        link:"/",
      },
      {
        title:"Innovation in Science Pursuit for Inspired Research (INSPIRE) Awards",
        desc:`Organized by the Department of Science and Technology (DST), INSPIRE Awards encourages undergraduate students
        to undertake innovative research projects in various scientific disciplines, including biology.`,
        link:"/",
      },
      {
        title:"Biotron",
        desc:`Biotron is a national-level biotechnology quiz competition organized by the Indian Society of Technical Education (ISTE).
        It tests the knowledge and understanding of undergraduate students in the field of biotechnology.`,
        link:"/",
      },
      {
        title:"Bio-Innovate",
        desc:`Organized by the Centre for Cellular and Molecular Platforms (C-CAMP), Bio-Innovate is an annual competition that
        invites innovators and entrepreneurs in the life sciences field to present their innovative ideas, products, or technologies.`,
        link:"/",
      },
      {
        title:"Biotech Innovation Challenge (BIC)",
        desc:`BIC is a national-level innovation challenge organized by the Indian Institute of Technology (IIT) Delhi. It aims to 
        encourage innovation and entrepreneurship in biotechnology by providing a platform for showcasing
         novel ideas and prototypes.`,
        link:"/",
      },
      {
        title:"Biotechnology Industrial Training Program (BITP)",
        desc:`Conducted by the Biotech Consortium India Limited (BCIL), BITP is a six-month training program that provides
        hands-on industry exposure to postgraduate students in biotechnology. It includes training at various
        biotech companies and research institutes.`,
        link:"/",
      },
      {
        title:"Genopedia",
        desc:`Genopedia is a national-level genetic engineering competition organized by the National Institute of Technology (NIT), Rourkela.
        It challenges undergraduate students to apply their knowledge of genetics and genetic engineering in solving real-world problems.`,
        link:"/",
      },
      {
        title:"BioSurge",
        desc:`BioSurge is a national-level biomedical engineering competition organized by the Indian Institute of Technology (IIT), Roorkee. It invites undergraduate students to showcase their innovations in the field of biomedical engineering.`,
        link:"/",
      },
      {
        title:"Indian Biological Engineering Competition (IBEC)",
        desc:`IBEC is an annual national-level competition organized by the Indian Biological Engineering Society (IBES). 
        It focuses on the application of engineering principles in biological systems and challenges participants
         with problem-solving tasks.`,
        link:"/",
      },
      {
        title:"Bioclave",
        desc:`Bioclave is a national-level biology quiz competition organized by the Indian Institute of Technology IIT, Guwahati. It tests participants' knowledge in various sub-disciplines of biology.`,
        link:"/",
      },
      {
        title:"Biotechnica",
        desc:`Biotechnica is a national-level biotechnology quiz competition organized by the Department of Biotechnology,
        Motilal Nehru National Institute of Technology (MNNIT), Allahabad`,
        link:"/",
      }


      
]