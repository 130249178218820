import { MdEmail } from 'react-icons/md';
import Styles from '../css/profileComponent.module.css'
// import linkedinicon from "../assets/logo/linkedin-icon.png";
// import emailicon from "../assets/logo/mail-icon.png";
import { FaLinkedin } from 'react-icons/fa';
export default function ProfileComponent(props) {
  return (
    <>
      <div className={Styles.card}>
        <div className={Styles.pimg}>
          <img src={props.img} alt="profile" />
        </div>
        <div className={Styles.name}>
          <h3>{props.name}</h3>
        </div>
        <div className={Styles.post}>
          <p>{props.post}</p>
        </div>
        <div className={Styles.teamConnect}>
          <div className={Styles.linkedin}>
            <a href={props.linkedinId} target='_blank'>
            <FaLinkedin className={Styles.linkedinicon}/>
            </a>
          </div>
          <div className={Styles.mail}>
            <a href={props.emailId} target='_blank'>
              <MdEmail className={Styles.mailicon}/>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
