import styles from "../css/newsCard.module.css";

const NewsCard = ({ img, desc, link }) => {
  // const { img, desc, link } = props.news.data;
  return (
    <div className={styles.newsCard}>
      <img className={styles.newsImage} src={img} alt="News-Pic" />
      <div className={styles.newsInfo}>{desc}</div>
      <a href={link} target="_blank">
        <button className={styles.newsButton}>Know More</button>
      </a>
    </div>
  );
};
export default NewsCard;
