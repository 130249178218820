import arunShukla from "../assets/Prof/arunShukla.jpg"
import ashwanikumar from "../assets/Prof/Prof. Ashwani Kumar Thakur.jpg"
import ramasubbu from "../assets/Prof/Prof. Ramasubbu Sankararamakrishnan.jpg"
import ashdhir from "../assets/Prof/ashdhir.jpeg"
import ash2 from "../assets/Prof/ash2.jpeg"
import ash3 from "../assets/Prof/ash3.jpeg"
import dhirk from "../assets/Prof/dhirk.jpeg"
import bushra from "../assets/Prof/bushra.jpeg"
import ash4 from "../assets/Prof/ash4.jpeg"


export const profAchievementData = [
  {
    name: "Dr. Arun Kumar Shukla",
    achievement:
      "The Infosys Prize 2023 in Life Sciences is awarded to Prof. Arun Kumar Shukla for his outstanding contributions to the biology of G-protein coupled receptors (GPCRs), a key component of cell function.",
    image: arunShukla,
    link:"https://www.linkedin.com/posts/arun-shukla-a264585_infosysprize2023-activity-7130477441768169472-wy63/",
    achievehead: "The Infosys Prize 2023 in Life Sciences"
  },
  {
    name: "Prof. Ashwani Kumar Thakur",
    achievement:
      "Prof. Ashwani Kumar Thakur, Department of Biological Sciences and Bioengineering, has been honoured with the Excellence-in-Teaching Award, 2023, by IIT Kanpur.",
    image: ashwanikumar,
    link: "https://www.instagram.com/p/Cw0AND-trA-/?img_index=1",
    achievehead: "Excellence-in-Teaching Award"
  },
  {
    name: "Prof. Ramasubbu Sankararamakrishnan",
    achievement:
      "Biosoc is delighted to share that Prof. Ramasubbu Sankararamakrishnan, Department of Biological Sciences and Bioengineering, has been honored with the prestigious Distinguished Teacher Award for 2023 by IIT Kanpur.",
    image: ramasubbu,
    link: "https://www.instagram.com/p/Cwz_frFN5dD/?img_index=1",
    achievehead: "Distinguished Teacher Award"
  },
  {
    name: "Prof. Ashok Kumar and Prof. Dhirendra S. Katti ",
    achievement:
      "elected as Presidents of the prestigious Biomaterials and Tissue Engineering Societies of India",
    image: ashdhir,
    link: "https://x.com/BSBEIITK1/status/1730232618778546197?s=20",
    achievehead: "Presidents of Biomaterials and Tissue Engineering Societies of India"
  },
  {
    name: "Prof.Ashok Kumar",
    achievement:
      "Prof. Ashok Kumar appointed as the Director of the IIT Kanpur-La Trobe University Research Academy. ",
    image: ash2,
    link: "https://twitter.com/BSBEIITK1/status/1753624882829803663/photo/1",
    achievehead: "Director of the IIT Kanpur-La Trobe University Research Academy"
  },
  {
    name: "Prof.Ashok Kumar",
    achievement:
      "Prof. Ashok Kumar @AKBioLabs1 has been chosen for the Distinguished Biomaterials Scientist Award by the Society of Biomaterials and Artificial Organs, India. Hearty congratulations from @BSBE family!",
    image: ash3,
    link: "https://twitter.com/BSBEIITK1/status/1714577966020378751/photo/1",
    achievehead: "Distinguished Biomaterials Scientist Award"
  },
  {
    name: "Prof. Dhirendra Katti",
    achievement:
    "Prof. Dhirendra Katti received TATA Innovation Fellowship (2022-23)",
    image: dhirk,
    link: "https://www.instagram.com/p/Cw0AND-trA-/?img_index=1",
    achievehead: "TATA Innovation Fellowship (2022-23)"
  },
  {
    name: "Prof. Bushra Ateeq",
    achievement:
      "elected as a Fellow of INSA for her contributions in Cancer Research",
    image: bushra,
    link: "https://twitter.com/BSBEIITK1/status/1702510258860728552?t=cREZVHMDLczfMEgWfCj1QQ&s=08",
    achievehead: "INSA Fellowship"
  },
  {
    name: "Prof. Ashok Kumar",
    achievement:
      "selected by the National Academy of Medical Sciences  for the ‘Dr. Nandagudi Suryanarayana Rao Academic Award’ 2023",
    image: ash4,
    link: "https://twitter.com/mfcem_iitk/status/1643929438449733633?t=UN5eVsnYhE0Wc7Ww_xSZjg&s=08",
    achievehead: "Dr. Nandagudi Suryanarayana Rao Academic Award’ 2023"
  }

  // Add more professors as needed
];
