import { useState } from "react";
import styles from "../css/aboutus.module.css";
// import { FounderCard } from "../components";
import { Helmet } from "react-helmet-async";

function ReadMore(props) {
  let paraType = props.type;
  let para = props.children;

  const [isReadMore, setIsReadMore] = useState(true);
  let toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  let paraElement;
  let showMoreText = " ...show more";
  let showLessText = " show less";

  if (window.innerWidth <= 750) {
    if (isReadMore) {
      paraElement = para.slice(0, 250);
    } else {
      paraElement = para;
    }
  } else {
    if (paraType === "about-dept") {
      if (isReadMore) {
        paraElement = para.slice(0, 599);
      } else {
        paraElement = para;
      }
    } else {
      paraElement = para;
      showMoreText = ".";
      showLessText = ".";
    }
  }

  return (
    <p>
      {paraElement}
      <span onClick={toggleReadMore} className={styles.readOrHide}>
        {isReadMore ? showMoreText : showLessText}
      </span>
    </p>
  );
}

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="BioSoc (Society of Department of Biological Sciences &
              Bioengineering IIT Kanpur) is a non-profit organization. Its primary objective is to
              establish a strong bond among BSBE students, alumni, and
              professors at IIT Kanpur."
        />
        <link rel="canonical" href="/aboutus" />
      </Helmet>

      <div className={styles.aboutUs}>
        <div className={styles.aboutBanner}>
          <div className={styles.head}>
            <h1>BioSoc IIT Kanpur</h1>
            <p>Where Bio Technology & Research Meets Community</p>
          </div>
        </div>
        <div className={styles.textInfo}>
          <div className={styles.intro}>
            <ReadMore>
              BioSoc (Society of Department of Biological Sciences &
              Bioengineering (BSBE) IIT Kanpur) is a non-profit organization.
              Its primary objective is to establish a strong bond among BSBE
              students, alumni, and professors at IIT Kanpur. By fostering open
              communication and facilitating the exchange of ideas and
              information, this society aims to create an inclusive platform for
              all members. With the unwavering support and collaboration of the
              passionate members and esteemed faculty, this society is committed
              to expanding its influence day by day, fostering a culture of open
              communication, idea sharing, and information exchange for the
              betterment of the entire community
            </ReadMore>
          </div>
          <hr />

          <div className={styles.dept}>
            <h1>About Department</h1>
            <ReadMore type="about-dept">
              Department of Biological Science and Bioengineering at the Indian
              Institute of Technology (IIT) Kanpur is a world-class program that
              combines the principles of biology and engineering to create a
              unique and challenging curriculum. With a focus on cutting-edge
              research and practical application, this program prepares students
              to tackle complex problems in the fields of biotechnology,
              bioinformatics, healthcare, and more. The department is consist of
              diverse group of individuals, who come from all over the world and
              bring a range of experiences and perspectives to the table. They
              are driven by a passion for science and a desire to make a
              difference in the world. The faculty and staff of the department
              are equally passionate and committed to supporting their students.
              They bring a wealth of expertise and experience to the program,
              and are dedicated to providing a supportive and collaborative
              learning environment where students can thrive. Whether it's
              designing new medical devices, developing innovative therapies, or
              conducting groundbreaking research, the students of this program
              are poised to make a real difference in the world. And with the
              support and guidance of the faculty and staff at IIT Kanpur, they
              are well-equipped to succeed and make their mark on the world. The
              department currently offers three academic programs; B. Tech, M.
              Tech and PhD in biological sciences & bioengineering. The
              department is housed in a three storied building, with 16 research
              labs, 6 labs for core facilities, and 3 teaching labs, besides the
              office space. From the initial strength of 4 faculty members in
              2002, today the BSBE department has grown with 16 faculty members
              and about 100 postgraduate (75 PhD. and 25 M. Tech.) and 145
              undergraduate students. Ongoing research projects are spread in
              broadly three major domains that include (a) molecular, cellular
              and developmental biology, (b) structural and computational
              biology and (c) bioengineering. In years to come, BSBE aspires to
              become one of the world’s leading centers for biomedical research,
              training and teaching.
            </ReadMore>
          </div>
          <hr />

          <div className={styles.mission}>
            <h1>Our Mission</h1>
            <ReadMore>
              The mission of the BSBE Society at IIT Kanpur is to establish a
              strong bond among BSBE students, alumni, and professors. By
              fostering open communication, facilitating the exchange of ideas
              and information, and promoting collaboration, the society aims to
              create an inclusive platform for all members. It is committed to
              enhancing learning and development, building a strong network, and
              promoting research and innovation within the field of Biological
              Science and Bioengineering. Through its mission, the society seeks
              to provide a supportive and vibrant environment for its members,
              enabling them to thrive and make a difference in their careers and
              in society
            </ReadMore>
          </div>
          <hr />
          <div className={styles.vision}>
            <h1>Our Vision</h1>
            <ReadMore>
              The BSBE Society envisions a diverse and inclusive community where
              every member feels valued and supported. It strives for excellence
              in education, aiming to produce highly skilled professionals in
              the field of Biological Science and Bioengineering. The society
              aspires for global recognition of the BSBE department at IIT
              Kanpur as a leading center for biomedical research, training, and
              teaching. It aims to create an ecosystem that attracts talented
              individuals, encourages groundbreaking research, and contributes
              significantly to the advancement of knowledge and innovation in
              the field. The society also aims to empower its members to make a
              positive impact
            </ReadMore>
          </div>
          <hr />
        </div>
        {/* Commenting till completion */}
        {/* <FounderCard /> */}
      </div>
    </>
  );
};

export default AboutUs;
