import profileimg from "../assets/logo/profileimg.jpg";
export const secyArray = [
  {
    img: profileimg,
    name: "Vrushabh",
    post: "Research and Projects",
    linkedinId: "#",
    emailId: "vdundri23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Ishanth Agarwal",
    post: "Research and Projects",
    linkedinId: "https://www.linkedin.com/in/ishant-agrawal-b4aa422a7/",
    emailId: "ishantag23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Enoch Emmanuel",
    post: "Research and Projects",
    linkedinId: "#",
    emailId: "enochem23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Kshitiz Tyagi",
    post: "Research and Projects",
    linkedinId: "https://www.linkedin.com/in/kshitiz-tyagi-b6440429b/",
    emailId: "ktyagi23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Ayushi Mishra",
    post: "Marketing and Fundraising",
    linkedinId: "#",
    emailId: "ayushim23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Md.Waqar Moid",
    post: "Marketing and Fundraising",
    linkedinId: "https://www.linkedin.com/in/waqar-moid-754a0427b/",
    emailId: "waqarmoid23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Dhruv Shetty",
    post: "Marketing and Fundraising",
    linkedinId: "https://www.linkedin.com/in/dhruv-shetty-b3b4892b9/",
    emailId: "sdhruv23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "P Movika",
    post: "Marketing and Fundraising",
    linkedinId: "#",
    emailId: "pmovika23@iitk.ac.in",
  },{
    img: profileimg,
    name: "Anudeep",
    post: "Web Dev",
    linkedinId: "https://www.linkedin.com/in/boya-anudeep-76955b293/",
    emailId: "anudeepb23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Abhay Saxena",
    post: "Web Dev",
    linkedinId: "https://www.linkedin.com/in/abhay-saxena-3a5503293/",
    emailId: "abhaysa23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Kushal Agarwal",
    post: "Web Dev",
    linkedinId: "https://www.linkedin.com/in/kushal-agrawal-1b2722298/",
    emailId: "kushalag23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Lokesh Guguloth",
    post: "Social Media",
    linkedinId: "#",
    emailId: "glokesh23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Aaliyah Ahmed",
    post: "Social Media",
    linkedinId: "https://www.linkedin.com/in/aaliyah-ahmed-276133313/",
    emailId: "aaliyaha23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Sritama Sarkar",
    post: "Events",
    linkedinId: "https://www.linkedin.com/in/sritama-sarkar-9703bb290/",
    emailId: "sritamas23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Harshit Agarwal",
    post: "Events",
    linkedinId: "https://www.linkedin.com/in/harshit-agarwal-75041326a/",
    emailId: "harshitag23@iitk.ac.in",
  },
  {
    img: profileimg,
    name: "Amit Kumar",
    post: "Events",
    linkedinId: "#",
    emailId: "a9097162676@gmail.com",
  },
  {
    img: profileimg,
    name: "Vatsal Mittal",
    post: "Events",
    linkedinId: "https://www.linkedin.com/in/vatsal-mittal-3b73a2284/",
    emailId: "vatsalmittal05@gmail.com",
  },
];
