import { useEffect, useState } from "react";
import Styles from "../css/contactus.module.css";
import { MdOutlineEmail } from "react-icons/md";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Banner from "../components/Banner";
const ContactUs = () => {
  //storing data locally in state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    subject: "",
    message: "",
  });
  const [successAlert, setSuccessAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [notFilled, setNotFilled] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  useEffect(() => {
    if (successAlert) {
      setShowSuccessAlert(true);
      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successAlert]);
  useEffect(() => {
    if (notFilled) {
      setShowWarningAlert(true); //this will show the warning alert
      const timer = setTimeout(() => {
        setShowWarningAlert(false); //will hide alert after 5 second
      }, 5000);
      return () => clearTimeout(timer); //Clean up the timer on unmount or state change
    }
  }, [notFilled]);

  // console.log(formData)
  //handling chnage and updating the value
  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: [value],
    }));
  }

  // Sending response to firebase dashboard
  const handleSubmit = async (e) => {
    e.preventDefault(); //to prevent data to save in browser tab
    const { name, email, phone, message, subject, country } = formData;
    if (name && email && message && subject && country) {
      const res = await fetch(
        "https://biosoc-ae06d-default-rtdb.asia-southeast1.firebasedatabase.app/contactFormData.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            phone,
            message,
            subject,
            country,
          }),
        }
      );
      if (res) {
        setFormData({
          name: "",
          email: "",
          phone: "",
          country: "",
          subject: "",
          message: "",
        });
        setSuccessAlert(true);
        setNotFilled(false);
        // alert("Thankyou! Message Sent Successfully, Our Team response you soon")
      }
    } else {
      setNotFilled(true);
      setSuccessAlert(false);
    }
  };
  return (
    <>
    <Helmet>
      <title>Contact Us</title>
      <meta name="description" content="We're here to help! If you have any questions, please don't hesitate to contact us. Our team is dedicated to providing you with the support you need." />
      <link rel="canonical" href="/contactus" />
    </Helmet>
    <div className={Styles.contactus}>
      <Banner 
      heading="Contact Us"/>
      <div className={Styles.contact}>
        <div className={Styles.contactdetail}>
          <div className={Styles.title}>
            <h3>Let's Start a Conversation</h3>
          </div>
          <p>
            We're here to help! If you have any questions, feedback, or need
            assistance, please don't hesitate to reach out to us. Our team is
            dedicated to providing you with the support you need.
          </p>
          <h4>Email:</h4>
          <p>
          Feel free to send us an email at <a href="mailto:biosoc@iitk.ac.in" target="_blank">biosoc@iitk.ac.in</a>, or to directly particular 
          <Link to="/team/team-24-25"> team member. </Link>  
          We aim to respond to all inquiries within 24 hours.
          </p>
          <h4>Mailing Address:</h4>
          <p>
            BioSoc, <br />
            Department of BioLogical
            <br />
            Sciences & Bioengineering
            <br />
            IIT Kanpur, 208016
          </p>
        </div>
        <div className={Styles.contactform}>
          <form action="post">
            <div className={Styles.name}>
              <label htmlFor="name">Name*</label> <br />
              <input
                name="name"
                type="text"
                id="name"
                required
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className={Styles.email}>
              <label htmlFor="email">Email*</label>
              <br />
              <input
                name="email"
                type="email"
                id="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className={Styles.phone}>
              <label htmlFor="phone">Phone Number</label>
              <br />
              <input
                name="phone"
                type="number"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className={Styles.country}>
              <label htmlFor="country">Country*</label>
              <br />
              <input
                name="country"
                type="text"
                required
                value={formData.country}
                onChange={handleChange}
              />
            </div>
            <div className={Styles.subject}>
              <label htmlFor="subject">Message Subject*</label>
              <br />
              <input
                name="subject"
                type="text"
                id="subject"
                required
                value={formData.subject}
                onChange={handleChange}
              />
            </div>
            <div className={Styles.message}>
              <label htmlFor="message">Message*</label>
              <br />
              <textarea
                name="message"
                id="message"
                required
                value={formData.message}
                onChange={handleChange}
                cols="32"
                rows="10"
              ></textarea>
            </div>
            {showSuccessAlert && (
              <div className={Styles.successAlert}>
                <p>
                  Thank you! Your message has been sent successfully. Our team
                  will respond to you soon.
                </p>
              </div>
            )}

            {showWarningAlert && (
              <div className={Styles.warningAlert}>
                <p>Sorry! Please fill in the required data.</p>
              </div>
            )}
            <div className={Styles.submit}>
              <button type="button" onClick={handleSubmit}>
                Send Message
              </button>
              {/* <Button text="Send Message" onClick={handleSubmit}/> */}
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};
export default ContactUs;
