import "../css/login.css";

const Login = () => {
  return (
    <div className="loginContainer">
      <div className="loginCardDown">
        <div className="loginCardUp">
          <input placeholder="username"></input>
          <input placeholder="password"></input>
          <br></br>
          <button>Login</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
