import { Dna } from "react-loader-spinner";

const PreLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Dna height="20%" width="20%" />
    </div>
  );
};

export default PreLoader;
