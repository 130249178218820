import styles from "../css/eventItem.module.css";

const EventItem = ({ desc, date, location }) => {
  // const { desc, date } = props.news.data;
  return (
    <div className={styles.eventItem}>
      <div className={styles.eventInfo}>{desc}</div>
      <p className={styles.eventDate}>{date}</p>
      <p className={styles.location}>{location}</p>
    </div>
  );
};
export default EventItem;
