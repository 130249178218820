import styles from "../css/callForTalks.module.css"
import React, { useState } from "react";
import Banner from "../components/Banner";
import { Button } from "../components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
const CallForTalks = () => {
  // Dummy data for available topics
  const topicsData = [
    {
      id: 1,
      topic: "Biodiversity Conservation",
      description:
        "Learn about the importance of biodiversity and conservation efforts.",
    },
    {
      id: 2,
      topic: "Biotechnology Innovations",
      description:
        "Explore recent innovations and advancements in biotechnology.",
    },
    {
      id: 3,
      topic: "Genetic Engineering Ethics",
      description:
        "Discuss the ethical considerations in genetic engineering and biotechnology.",
    },
    {
      id: 4,
      topic: "Environmental Sustainability",
      description: "Addressing sustainable practices for a healthier planet.",
    },
    {
      id: 5,
      topic: "Custom Topic Of Your Choice",
      description: "Any Topic related to our field or Alumni",
    },
  ];

  // State for form inputs
  const [formData, setFormData] = useState({
    name: "",
    batch: "",
    branch: "",
    email: "",
    phone: "",
    selectedTopic: "",
    customTopicDescription: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: [value],
    }));
  };

   // Sending response to firebase dashboard
   const handleSubmit = async (e) => {
    e.preventDefault(); //to prevent data to save in browser tab
    const { name, batch, branch, email, phone, selectedTopic, customTopicDescription } = formData;
    if (name && batch && branch && email && selectedTopic) {
      const res = await fetch(
        "https://biosoc-ae06d-default-rtdb.asia-southeast1.firebasedatabase.app/callForTalksFormData.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            phone,
            batch,
            branch,
            selectedTopic,
            customTopicDescription
          }),
        }
      )
      if(res){
        setFormData({
          name: "",
          batch: "",
          branch: "",
          email: "",
          phone: "",
          selectedTopic: "",
          customTopicDescription: "",
        })
          // setSuccessAlert(true);
        // setNotFilled(false);
        alert("Thankyou! Your Request Sent Successfully, Our Team response you soon")
      }else{
        alert("Error! Try Again")
      }
    } 
    // else {
    //   setNotFilled(true);
    //   setSuccessAlert(false);
    // }
  };
  return (
    <>
    <Helmet>
      <title>Call For Talks</title>
      <meta name="content" description="Join our 'Call for Talks' event! Alumni, share your experiences and insights with our community. Inspire current students and reconnect with fellow alumni. Sign up now!"/>
      <link rel="canonical" href="/call-for-talks" />
    </Helmet>
    <div className={styles.talksPage}>
      {/* <h1 style={{ color: "#015fb1" }}>Calls For Talks</h1> */}
      <Banner 
      heading = "Call For Talks"
      description="Share your experiences and insights with our community. Inspire current students and reconnect with fellow alumni." />
      <div className={styles.container}>
      <div className={styles.topicsContainer}>
        <h2 style={{ color:"black" }}>AVAILABLE TOPICS</h2>
        <ul>
          {topicsData.map((topic) => (
            <li key={topic.id}>
              <strong>{topic.topic}</strong>: {topic.description}
            </li>
          ))}
        </ul>
        <a href="#request-topic"><Button text="Request Topic"/></a>
        <Link to="/contactus"><Button text="Have Question?"/></Link>
      </div>

      <div id="request-topic" className={styles.formContainer}>
        <h2 style={{ color: "#015fb1" }}>REQUEST TOPIC</h2>
        <form  onSubmit={handleSubmit}>
          <label htmlFor="name">Name*:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="batch">Batch*:</label>
          <input
            type="text"
            id="batch"
            name="batch"
            value={formData.batch}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="branch">Branch*:</label>
          <input
            type="text"
            id="branch"
            name="branch"
            value={formData.branch}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="email">Email*:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />

          <label htmlFor="selectedTopic">Select Topic*:</label>
          <select
            id="selectedTopic"
            name="selectedTopic"
            onChange={handleInputChange}
            required
          >
            <option value="" disabled>
              Select a topic
            </option>
            {topicsData.map((topic) => (
              <option key={topic.id} value={topic.topic}>
                {topic.topic}
              </option>
            ))}
          </select>

          <label htmlFor="customTopicDescription">
            Custom Topic Description (optional):
          </label>
          <textarea
            id="customTopicDescription"
            name="customTopicDescription"
            value={formData.customTopicDescription}
            onChange={handleInputChange}
          ></textarea>

          <button type="submit">Request Topic</button>
        </form>
      </div>
      </div>
    </div>
    </>
  );
};

export default CallForTalks;
