import styles from "../css/achievements.module.css";
import Button from "../components/Button";
import studentAchievementsData from "../data/studentAchievementsData";
import { profAchievementData } from "../data/profAchievementData";
import { NewsCard } from "../components";
import { GoNote } from "react-icons/go";
import {
    TbSquareChevronLeftFilled,
    TbSquareChevronRightFilled,
  } from "react-icons/tb";
  import { useEffect } from "react";
import Banner from "../components/Banner";
const Achievements = () => {
    useEffect(() => {
        // getEvents();
        // getNews();
        const leftBtn1 = document.getElementById("left1");
        const rightBtn1 = document.getElementById("right1");
        const studentContainer = document.getElementById("studentContainer");
        const leftBtn2 = document.getElementById("left2");
        const rightBtn2 = document.getElementById("right2");
        const facultyContainer = document.getElementById("facultyContainer");
    
        leftBtn1.onclick = () => {
          studentContainer.scrollLeft -= 320;
        };
        rightBtn1.onclick = () => {
          studentContainer.scrollLeft += 320;
        };
        leftBtn2.onclick = () => {
          facultyContainer.scrollLeft -= 320;
        };
        rightBtn2.onclick = () => {
            facultyContainer.scrollLeft += 320;
        };
      }, []);
  return (
    <>
    <Banner heading="Milestones We've Reached"/>
      <div className={styles.achievements}>
        <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.student}>
                <h2 className={styles.subHeading}>Student Achievements</h2>
                <div className={styles.studentContainer} id="studentContainer">
                {
                    studentAchievementsData.map(( data, index)=>{
                        return (
                            <NewsCard
                            key={index}
                            img = {data.image}
                            desc ={data.shortDesc}
                            link = {data.link}
                            />
                        )
                    })
                }
                </div>
                <span className={styles.sliderLeft} id="left1">
            <TbSquareChevronLeftFilled />
          </span>
          <span className={styles.sliderRight} id="right1">
            <TbSquareChevronRightFilled />
          </span>
            </div>
            <div className={styles.faculty}>
                <h2 className={styles.subHeading}>Faculty Achievements</h2>
                <div className={styles.facultyContainer} id="facultyContainer">
                {
                    profAchievementData.map(( data, index)=>{
                        return (
                            <NewsCard
                            key={index}
                            img = {data.image}
                            desc ={data.achievement}
                            link = {data.link}
                            />
                        )
                    })
                }
                </div>
                <span className={styles.sliderLeft} id="left2">
            <TbSquareChevronLeftFilled />
          </span>
          <span className={styles.sliderRight} id="right2">
            <TbSquareChevronRightFilled />
          </span>
            </div>
          </div>
          <div className={styles.sidebar}>
            <div className={styles.btn}>
              <Button text="Interact with Us" />
            </div>
            <div className={styles.btn}>
              <Button text="Offer Workshop" />
            </div>
            <div className={styles.btn}>
            {/* <GoNote className={styles.subheadicon} /> */}
              <Button text="Placement Procedure" />
            </div>
            <div className={styles.btn}>
              <Button text="Recruit Talent" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Achievements;
