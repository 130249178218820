import React from "react";
import styles from "../css/error.module.css";
import { NavLink } from "react-router-dom";

const Error = () => {
  return (
    <div className={styles.notfoundpage}>
      <h1>404 : Not Found</h1>
      <p>Sorry, This page doesn't exist</p>
      <div className={styles.navigate}>
        <div>
          <button className={styles.back}>
            <NavLink to="/">Back to Home</NavLink>
          </button>
        </div>
        <div>
          <button className={styles.contact}>
            <NavLink to="ContactUs">Contact Us</NavLink>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Error;
