import { getFirestore, getDocs, collection } from "firebase/firestore";
import { firebaseApp } from "./";
import { useState } from "react";
const db = getFirestore(firebaseApp);

const getAllDocs = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    let docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({
        id: doc.id,
        data: doc.data(),
      });
    });
    return docs;
  } catch (error) {
    console.log("Error in fetching data: ", error);
  }
};
export default db;
export { getAllDocs };
