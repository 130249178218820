import React from "react";
import styles from "../css/ourstudents.module.css";
import { GrAchievement } from "react-icons/gr";
import { GrUserExpert } from "react-icons/gr";
import { GoNote } from "react-icons/go";
import { SiSemanticscholar } from "react-icons/si";
import { MdBusinessCenter } from "react-icons/md";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
const OurStudents = () => {
  return (
    <>
      <Helmet>
        <title>BioSoc | Our Students</title>
        <meta name="description" content="our students" />
        <link rel="canonical" href="/our-students" />
      </Helmet>
      <div className={styles.ourStudents}>
        <div className={styles.banner}>
          <h1 className={styles.heading}>Our Students</h1>
        </div>
        <div className={styles.subHeadings}>
          <div className={styles.subHeading}>
            <a href="#1">
              <GrAchievement className={styles.subheadicon} />
            </a>
            <p>Achievements</p>
          </div>
          <div className={styles.subHeading}>
            <a href="#2">
              <GrUserExpert className={styles.subheadicon} />
            </a>
            <p>Our Experties</p>
          </div>
          <div className={styles.subHeading}>
            <a href="#3">
              <SiSemanticscholar className={styles.subheadicon} />
            </a>
            <p>Course Work</p>
          </div>
          <div className={styles.subHeading}>
            <a href="#4">
              <GoNote className={styles.subheadicon} />
            </a>
            <p>Placement Procedure</p>
          </div>
          <div className={styles.subHeading}>
            <a href="#5">
              <MdBusinessCenter className={styles.subheadicon} />
            </a>
            <p>Recruit Talent</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.description}>
            IIT Kanpur (IITK) students are known for their incredible
            intelligence, creativity, and determination. They succeed in a
            challenging academic setting that encourages them to think
            critically, solve complex problems, and engage in innovative
            research. Beyond their studies, they participate in various
            activities, excelling in areas like entrepreneurship, arts, and
            sports. Working together and striving for excellence, IITK students
            make meaningful contributions to society and the world, reflecting
            their values of hard work, honesty, and social responsibility.
          </div>
          <div id="1" className="achievement">
            <h2>Achievements</h2>
            <div>detail : Coming Soon</div>
            <Link to="/achievements">
              <button>View All in Detail</button>
            </Link>
          </div>
          <div id="2" className="achievement">
            <h2>Our Experties</h2>
            <div>detail : Coming Soon, Till you can visit to contact Page</div>
          </div>
          <div id="3" className="achievement">
            <h2>Course Work</h2>
            <div>detail : Coming Soon, Till you can visit to contact Page</div>
          </div>
          <div id="4" className="achievement">
            <h2>Placement Procedure</h2>
            <div>detail : Coming Soon, Till you can visit to contact Page</div>
          </div>
          <div id="5" className="achievement">
            <h2>Recruit Talent</h2>
            <div>detail : Coming Soon, Till you can visit to contact Page</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurStudents;
