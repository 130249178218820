// ProfAchievements.jsx
import React from "react";
import "../css/profAchievements.css";
import { profAchievementData } from "../data/profAchievementData";
const ProfAchievements = () => {
  return (
    <div className="prof-achievements-container">
      <div className="prof-achievements-banner">
      <h1 className="prof-achievements-heading" style={{ color: "#015fb1" }}>
        Professor Achievements
      </h1>
      </div>
      <div className="prof-achievements-All">
      <div className="prof-achievements-grid">
        {profAchievementData.map((data, index) => (
          <div key={index} className="prof-achievement-item">
            <img
              src={data.image}
              alt={`Professor ${index + 1}`}
              className="prof-achievement-image"
            />
            <div
              className="prof-achievement-details"
              style={{ border: `2px solid #4d954c` }}
            >
              <h2 className="prof-name" style={{ color: "#015fb1" }}>
                {data.name}
              </h2>
              <h4 className="achieve-head" style={{ color: "#486856" }}>
                {data.achievehead}
              </h4>
              <p
                className="prof-achievement"
                style={{ color: "rgb(83, 83, 83)" }}
              >
                {data.achievement}
              </p>
              <a rel = "noreferrer" href = {data.link} target="_blank" style={{ color: "rgb(28 218 179)", fontWeight: "bold"}}> View in detail</a>
            </div>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default ProfAchievements;
