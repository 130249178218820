import { ProjectCard } from "../components/index";
import Styles from "../css/projectcard.module.css";
import { useState, useEffect } from "react";
import { getAllDocs } from "../config";
import Banner from "../components/Banner";
const Projects = () => {
  const [profProjects, setProfProjects] = useState([]);
    const getprofProjects = async () => {
      let data = await getAllDocs("profProjects");
      if (data != null) {
        setProfProjects(data);
      }
    };
    const [societyProjects, setSocietyProjects] = useState([]);
    const getSocietyProjects = async () => {
      let data = await getAllDocs("societyProjects");
      if (data != null) {
        setSocietyProjects(data);
      }
    };
    const [otherProjects, setOtherProjects] = useState([]);
    const getOtherProjects = async () => {
      let data = await getAllDocs("otherProjects");
      if (data != null) {
        setOtherProjects(data);
      }
    };
  
    useEffect(() => {
      getprofProjects();
      getSocietyProjects();
      getOtherProjects();
    }, []);
  return (
    <>
      <Banner heading="Transform Ideas Into Reality Through Projects"/>
      <div className={Styles.background}>
        <h2 className={Styles.titles}>Projects from Professors:</h2>
        <div className={Styles.cards}>
        {profProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
        <h2 className={Styles.titles}>Society Projects:</h2>
        <div className={Styles.cards}>
        {societyProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
        <h2 className={Styles.titles}>Other Projects:</h2>
        <div className={Styles.cards}>
        {otherProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Projects;
