import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../css/navbarDes.module.css";
import logo from "../assets/images/logo.png";
import location from "../assets/images/location.png";
import { FaHome } from "react-icons/fa";
import { BiSolidChevronDown } from "react-icons/bi";
import { BsCalendar3 } from "react-icons/bs";
import { RiLoginBoxFill } from "react-icons/ri";
import { RiBookletLine } from "react-icons/ri";
import { Dropdown1, Dropdown2, Dropdown3, Dropdown4 } from "./Dropdown";
import { useEffect } from "react";

const NavbarDesktop = () => {
  const { pathname } = useLocation()
  console.log(pathname)

  const [dropdowns, setDropdowns] = useState({
    dropdown1: false,
    dropdown2: false,
    dropdown3: false,
    dropdown4: false,
  });
  const onMouseEnter = (dropdownId) => {
    setDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [dropdownId]: true,
    }));
  };

  const onMouseLeave = (dropdownId) => {
    setDropdowns((prevDropdowns) => ({
      ...prevDropdowns,
      [dropdownId]: false,
    }));
  };

  useEffect(() => {
    const navbar = document.querySelector(`.${styles.navbarBottom}`);
    if (navbar) {
      if (pathname !== "/") {
        navbar.classList.add(styles.scrolled);
      } else {
        navbar.classList.remove(styles.scrolled);
      }
    }
  }, [pathname]);

  useEffect(() => {
    const toggleNavbar = () => {
      const navbar = document.querySelector(`.${styles.navbarBottom}`);
      if (navbar) {
        if (window.scrollY > 150) {
          navbar.classList.add(styles.scrolled);
        } else {
          navbar.classList.remove(styles.scrolled);
        }
      }
    };

    if (pathname === "/") {
      window.addEventListener("scroll", toggleNavbar);
    }

    return () => {
      window.removeEventListener("scroll", toggleNavbar);
    };
  }, [pathname]);

  // useEffect(() => {
  //   const hoverRotateIcons = document.querySelectorAll(
  //     `.${styles.hoverRotate}`
  //   );

  //   hoverRotateIcons.forEach((hoverRotate) => {
  //     hoverRotate
  //       .closest(`.${styles.navbarItem}`)
  //       .addEventListener("mouseover", () => {
  //         console.log("test");
  //         hoverRotate.style.transform = "rotate(180deg)";
  //         hoverRotate.style.transition = "0.4s";
  //       });
  //     hoverRotate
  //       .closest(`.${styles.navbarItem}`)
  //       .addEventListener("mouseout", () => {
  //         hoverRotate.style.transform = "rotate(0deg)";
  //         hoverRotate.style.transition = "0.4s";
  //       });
  //   });
  //   return () => {
  //     hoverRotateIcons.forEach((hoverRotate) => {
  //       hoverRotate
  //         .closest(`.${styles.navbarItem}`)
  //         .removeEventListener("mouseover", () => {
  //           hoverRotate.style.transform = "rotate(180deg)";
  //           hoverRotate.style.transition = "0.4s";
  //         });
  //       hoverRotate
  //         .closest(`.${styles.navbarItem}`)
  //         .removeEventListener("mouseout", () => {
  //           hoverRotate.style.transform = "rotate(0deg)";
  //           hoverRotate.style.transition = "0.4s";
  //         });
  //     });
  //   };
  // }, []);

  // useEffect(() => {
  //   const navbarItems = document.querySelectorAll(`.${styles.navbarItem}`);

  //   navbarItems.forEach((navbarItem) => {
  //     const hoverRotate = navbarItem.querySelector(`.${styles.hoverRotate}`);

  //     navbarItem.addEventListener("mouseover", () => {
  //       if (hoverRotate) {
  //         console.log("test");
  //         hoverRotate.style.transform = "rotate(180deg)";
  //         hoverRotate.style.transition = "0.4s";
  //       }
  //     });

  //     navbarItem.addEventListener("mouseout", () => {
  //       if (hoverRotate) {
  //         hoverRotate.style.transform = "rotate(0deg)";
  //         hoverRotate.style.transition = "0.4s";
  //       }
  //     });
  //   });

  //   return () => {
  //     navbarItems.forEach((navbarItem) => {
  //       navbarItem.removeEventListener("mouseover", () => {
  //         if (hoverRotate) {
  //           hoverRotate.style.transform = "rotate(180deg)";
  //           hoverRotate.style.transition = "0.4s";
  //         }
  //       });

  //       navbarItem.removeEventListener("mouseout", () => {
  //         if (hoverRotate) {
  //           hoverRotate.style.transform = "rotate(0deg)";
  //           hoverRotate.style.transition = "0.4s";
  //         }
  //       });
  //     });
  //   };
  // }, []);

  return (
    <div className={styles.navbar} style={{ backgroundImage: (pathname === "/") ? 'linear-gradient(rgba(0, 0, 0, 0.732), transparent)' : null }}>
      {(pathname === "/") ? < div className={styles.navbarTop}>
        <div className={styles.navbarLeft}>
          <img className={styles.navbarLogo} src={logo} alt="logo"></img>
          <div className={styles.navbarLogoText}>
            <h1>BioSoc</h1>
          <p>Society of BSBE, IIT Kanpur</p>
          </div>
        </div>
        <div className={styles.navbarRight}>
          <div className={styles.calender}>
            <span>
              <RiBookletLine />
            </span>
            Brochure
          </div>
          <Link to="/login">
            <div className={styles.login}>
              <span>
                <RiLoginBoxFill />
              </span>
              Login
            </div>
          </Link>
        </div>
      </div> : null}

      <ul className={styles.navbarBottom}>
        <li className={`${styles.navbarItem} ${styles.homeIcon}`}>
          <Link to="/">
            <FaHome />{" "}
          </Link>
        </li>
        <li className={styles.navbarItem}>
          <Link to="/aboutUs">About Us</Link>
        </li>
        <li
          className={styles.navbarItem}
          onMouseEnter={() => onMouseEnter("dropdown1")}
          onMouseLeave={() => onMouseLeave("dropdown1")}
        >
          For Students
          <span className={styles.hoverRotate}>
            <BiSolidChevronDown />
          </span>
          {dropdowns.dropdown1 && <Dropdown1 />}
        </li>
        <li
          className={styles.navbarItem}
          onMouseEnter={() => onMouseEnter("dropdown2")}
          onMouseLeave={() => onMouseLeave("dropdown2")}
        >
          For Companies
          <span className={styles.hoverRotate}>
            <BiSolidChevronDown />
          </span>
          {dropdowns.dropdown2 && <Dropdown2 />}
        </li>
        <li
          className={styles.navbarItem}
          onMouseEnter={() => onMouseEnter("dropdown3")}
          onMouseLeave={() => onMouseLeave("dropdown3")}
        >
          About Faculty
          <span className={styles.hoverRotate}>
            <BiSolidChevronDown />
          </span>
          {dropdowns.dropdown3 && <Dropdown3 />}
        </li>

        <li
          className={styles.navbarItem}
          onMouseEnter={() => onMouseEnter("dropdown4")}
          onMouseLeave={() => onMouseLeave("dropdown4")}
        >
          For Alumni
          <span className={styles.hoverRotate}>
            <BiSolidChevronDown />
          </span>
          {dropdowns.dropdown4 && <Dropdown4 />}
        </li>
        <li className={styles.navbarItem}>
          <Link to="/team/team-24-25">Team</Link>
        </li>
        <li className={styles.navbarItem}>
          <Link to="/contactus">Contact Us</Link>
        </li>
      </ul>
    </div >
  );
};

export default NavbarDesktop;
