import ProfileComponent from "../components/profileComponent";
import Styles from "../css/teamPage.module.css";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import allTeamImg from '../assets/images/allTeamImg.jpg'
import { ocArray, cArray, secyArray } from "../data/teamData2324";
export default function Team2324() {
  return (
    <>
    <Helmet>
        <title>Our Team</title>
        <meta
          name="description"
          content="We're here to help! If you have any questions, please don't hesitate to reachout us. Our team is dedicated to providing you with the support you need."
        />
        <link rel="canonical" href="/team/team-23-24" />
      </Helmet>
      <div className={Styles.ourTeam}>
        <div className={Styles.banner}>
          {/* <img src={allTeamImg} alt="BioSoc All Team (2023-24)" /> */}
          <h1>Team (23-24)</h1>
        </div>
             <div className={Styles.allTeam}>
             <ul>
              <li><button type="button"><NavLink className={({isActive})=>(isActive?Styles.activeLink:Styles.notActiveLink)} to="/team/team-24-25">Team (24-25)</NavLink></button></li>
              <li><button type="button"><NavLink className={({isActive})=>(isActive?Styles.activeLink:Styles.notActiveLink)} to="/team/team-23-24">Team (23-24)</NavLink></button></li>
            </ul>
          </div>
        <div className={Styles.overallCoordinator}>
          <h2 style={{ textAlign: "center" }}>Overall Co-ordinator</h2>

          <div className={Styles.overallCordiProfile}>
            {ocArray.map((data) => (
              <ProfileComponent
                img={data.image}
                name={data.name}
                linkedinId={data.linkedinId}
                emailId={data.emailId}
                post={data.post}
              />
            ))}
          </div>
        </div>
        <div className={Styles.coOrdinator}>
          <h2 style={{ textAlign: "center" }}>Co-ordinators</h2>
          <div className={Styles.cordiProfile}>
            {cArray.map((data) => {
              return (
                <>
                  <ProfileComponent
                    img={data.img}
                    name={data.name}
                    post={data.post}
                    linkedinId={data.linkedinId}
                    emailId={data.emailId}
                  />
                </>
              );
            })}
          </div>
        </div>

        <div className={Styles.secretary}>
          <h2 style={{ textAlign: "center" }}>Secretary</h2>
          <div className={Styles.secyProfile}>
            {secyArray.map((data) => {
              return (
                <>
                  <ProfileComponent
                    img={data.img}
                    name={data.name}
                    post={data.post}
                    linkedinId={data.linkedinId}
                    emailId={data.emailId}
                  />
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
