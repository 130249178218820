// FarewellDiaryPage.jsx

import React from "react";
import "../css/farewellDiary.css"; // Import the corresponding CSS file

const FarewellDiaryPage = () => {
  // Dummy data for farewell diary entries
  const diaryEntries = [
    {
      id: 1,
      topic: "Memorable Moments",
      article:
        "Remember the laughter, the challenges, and the triumphs. Our time together has been filled with unforgettable moments that will forever hold a special place in our hearts.",
      link: "https://example.com/memorable-moments",
    },
    {
      id: 2,
      topic: "Memorable Moments",
      article:
        "Remember the laughter, the challenges, and the triumphs. Our time together has been filled with unforgettable moments that will forever hold a special place in our hearts.",
      link: "https://example.com/memorable-moments",
    },
    {
      id: 3,
      topic: "Memorable Moments",
      article:
        "Remember the laughter, the challenges, and the triumphs. Our time together has been filled with unforgettable moments that will forever hold a special place in our hearts.",
      link: "https://example.com/memorable-moments",
    },
    {
      id: 4,
      topic: "Memorable Moments",
      article:
        "Remember the laughter, the challenges, and the triumphs. Our time together has been filled with unforgettable moments that will forever hold a special place in our hearts.",
      link: "https://example.com/memorable-moments",
    },
    {
      id: 5,
      topic: "Memorable Moments",
      article:
        "Remember the laughter, the challenges, and the triumphs. Our time together has been filled with unforgettable moments that will forever hold a special place in our hearts.",
      link: "https://example.com/memorable-moments",
    },
    {
      id: 6,
      topic: "Memorable Moments",
      article:
        "Remember the laughter, the challenges, and the triumphs. Our time together has been filled with unforgettable moments that will forever hold a special place in our hearts.",
      link: "https://example.com/memorable-moments",
    },
    {
      id: 7,
      topic: "Memorable Moments",
      article:
        "Remember the laughter, the challenges, and the triumphs. Our time together has been filled with unforgettable moments that will forever hold a special place in our hearts.",
      link: "https://example.com/memorable-moments",
    },
    {
      id: 8,
      topic: "Memorable Moments",
      article:
        "Remember the laughter, the challenges, and the triumphs. Our time together has been filled with unforgettable moments that will forever hold a special place in our hearts.",
      link: "https://example.com/memorable-moments",
    },
    // Add more diary entries as needed
  ];

  return (
    <div className="farewell-diary-page">
      <h1 style={{ color: "#015fb1" }}>Farewell Diary</h1>

      <div className="diary-entries">
        {diaryEntries.map((entry) => (
          <div key={entry.id} className="diary-card">
            <h2 style={{ color: "#015fb1" }}>{entry.topic}</h2>
            <p style={{ color: "rgb(83, 83, 83)" }}>{entry.article}</p>
            <a
              href={entry.link}
              target="_blank"
              rel="noopener noreferrer"
              className="know-more-link"
            >
              Know More
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FarewellDiaryPage;
