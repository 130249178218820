import styles from "../css/gallerycard.module.css"
export default function GalleryCard (props){
    return (
        <>
        <div className={styles.galleryItem}>  
                <img
                  src={props.img}
                  alt={`Gallery Image ${props.key + 1}`}
                  className={styles.galleryImage}
                />
                <div className={styles.imageOverlay}>
                  <p className={styles.imageText} style={{ color: "white" }}>
                    {props.info}
                  </p>
                </div>
            </div>
        </>
    )
}