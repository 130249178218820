import styles from "../css/scholarships.module.css";
import Banner from "../components/Banner";
const Scholarships = () => {
  return (
    <div className={styles.scholarships}>
       <Banner 
        heading = "Scholarships" />
      <div className={styles.container}>
        <div className={styles.info}>
          <p className={styles.title}>NTSE</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>
            10th and 12th: Rs 1,250
            <br />
            Undergraduate Programs: Rs 2,000
            <br />
            PhD: UGC guidelines
            <br />
            <br />
            Note: The amounts mentioned are on a monthly basis.
          </p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            Available only for class 10th students across the country, NTSE is
            conducted in 2 parts. The 1st stage is a state level examination and
            those who are shortlisted for the 2nd level will have to compete at
            a national level. At both levels, the applicant’s Mental Abilities
            and SAT (Scholastic Aptitude Test) will be assessed.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>KVPY</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>Rs 5,000 – Rs 7,000 per month</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            Funded by the Department of Science and Technology of the Government
            of India, KVPY is a renowned scholarship exam organised to shortlist
            candidates with a strong scientific temperament and are willing to
            pursue Basic Science courses/are pursuing/ want to build a career in
            Research. Candidates belonging to class 11th, 12th and 1st year of
            the undergraduate programs stand eligible to appear for the
            examination. Majorly, there are 3 streams of the KVPY exam namely,
            SA (class 11th), SX (class 12th), and SB (for the undergraduate
            program).
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>NSO</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>Rs 1,000 – Rs 50,000</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            Potential students of classes 1st to 12th can try their luck over
            this scholarship exam. Organised by the Science Olympiad Foundation
            (SOF), NSO strives to gauge through a candidate’s knowledge of
            Science subject across classes. It is organised on 2 levels. The 1st
            level of the exam consists of Logical Reasoning, Science, and
            Achiever’s Section. While the 2nd level is only open for those who
            shine out in the 1st level and are enrolled in classes 3rd-12th.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>ISTSE</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>
            Rs 500 – Rs 1,500 per month depending on the rank.
          </p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            1st to 10th can apply either through the school or independently.
            The candidates who have applied independently will take the online
            examination while offline will be the medium for those who are
            applying via schools. The exam will include sections like
            Mathematics, Scholars Section, Science, English, and Mental and
            Logical Ability along with 16% for fair play.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>AIYSEE</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>
            Varies based on the slab you fall under.
          </p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The honorable Ministry of Finance conducts this scholarship entrance
            exam every year to facilitate the route of higher education for the
            academically brilliant students. Those who passed class 12th
            examinations with at least 50 % marks can register for this test.
            The aspirants who want to start their career in Engineering or
            pursue Medical Science courses can avail the benefits of this
            scholarship. Applicants will be shortlisted through one single
            scholarship exam which will have questions from sections like
            Physics, Chemistry, Biology/Mathematics.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>INSPIRE</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>80,000 per annum.</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            Department of Science & Technology (DST) provides
            INSPIRE-Scholarship for Higher Education (INSPIRE-SHE) for
            attracting meritorious students to study basic and natural sciences
            at the college and university level. The scholarship amount under
            SHE component is Rs 5,000/- p.m. (60,000/- per annum) + Mentorship
            grant 20,000/- per annum.Meritorious Students with aggregate marks
            within top 1% of their Class XII examination of any State/ Central
            Board (same year i.e. 2021) in India are eligible to apply. In
            addition, the student must be pursuing courses in Natural and Basic
            Sciences at the B.Sc., B.S., and Int. M.Sc./M.S. level.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>DBT Junior Research Fellowship</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>
            31,000 for the first two years and 35,000 per month in third year
          </p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The DBT Junior Research Fellowship (DBT-JRF) is a prestigious
            fellowship program offered by the Department of Biotechnology (DBT),
            which is a part of the Ministry of Science and Technology,
            Government of India. The DBT-JRF program aims to promote research
            and innovation in the field of biotechnology.he fellowship is open
            to Indian nationals who have a postgraduate degree in Biotechnology,
            Life Sciences, or related subjects. The duration of the DBT-JRF
            fellowship is typically for a period of three years.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>Ramanujan Fellowship</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>INR 1,25,000</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The Ramanujan Fellowship is a prestigious fellowship program offered
            by the Government of India's Science and Engineering Research Board
            (SERB). The fellowship is named after the renowned Indian
            mathematician Srinivasa Ramanujan and is designed to support
            outstanding scientists and researchers in the field of science and
            engineering.: The fellowship is open to Indian nationals who have a
            Ph.D. degree in Science, Engineering, or Medicine. Candidates must
            have demonstrated exceptional research potential and have made
            significant contributions to their respective fields.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>Dr. APJ Abdul Kalam IGNITE Awards</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>None</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The Dr. APJ Abdul Kalam IGNITE Awards is an initiative launched by
            the National Innovation Foundation (NIF) in India. The awards aim to
            recognize and promote creativity and innovation among children and
            young students in the country. The late Dr. APJ Abdul Kalam, former
            President of India, was known for his support and encouragement of
            young minds, and these awards are named in his honor.The awards are
            open to children and students up to the age of 17 years, studying in
            schools or colleges in India.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>Green Olympiad</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>None</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The Green Olympiad is an annual environmental examination conducted
            by the Energy and Resources Institute (TERI), an organization based
            in India. The aim of the Green Olympiad is to raise awareness and
            assess students' knowledge and understanding of environmental
            issues.The Green Olympiad is open to students from classes 4 to 10,
            studying in schools across India and abroad. The examination is
            conducted in both English and Hindi.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>NEST Junior Scholarship Test</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>
            50,000 for NEST MASTER and 20,000 for NEST BASIC
          </p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The NEST Junior Scholarship Test is an examination conducted by the
            National Institute of Career Education and Scholarships (NICE
            Foundation) in India. It is designed to identify and reward talented
            students in various academic disciplines.The scholarship test is
            open to students studying in classes 9, 10, 11, and 12 in schools
            across India.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>Biotechnology Ignition Grant (BIG)</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>Up to INR 50 lakhs</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The Biotechnology Ignition Grant (BIG) is a funding initiative
            provided by the Biotechnology Industry Research Assistance Council
            (BIRAC), which is a government organization under the Department of
            Biotechnology (DBT), Government of India. The BIG scheme aims to
            support early-stage startups and entrepreneurs in the field of
            biotechnology and help them convert their innovative ideas into
            commercially viable products or services.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>
            NCERT Doctoral Fellowships in Biotechnology
          </p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>Rs. 23,000/- per month</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            Objectives of the Scheme:
            <br />
            (a) To provide opportunities to young scholars to research the field
            of education from different disciplinary perspectives.
            <br />
            (b) To build the knowledge base of education in the contemporary
            context. Scope of the Scheme.
            <br />
            The NCERT Doctoral Fellowships are intended to enable young scholars
            to pursue doctoral research in education and other related
            disciplines. Doctoral Fellows can pursue their work in a recognized
            university/research institution of their choice. The NCERT
            encourages original thinking in conceptualization of the problem and
            use of innovative methodology in conduct of the research related to
            school education.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>Tata Scholarships</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>Up to ₹6,00,000</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            Tata Scholarships refer to various scholarship programs offered by
            the Tata group of companies in India. The Tata group is one of the
            largest conglomerates in the country and has established several
            scholarship initiatives to support deserving students in their
            pursuit of higher education.To be qualified for this financial aid:
            <br />
            (a)The female scholars must possess a bachelor's degree from a
            distinguished Indian university. <br />
            (b) Individuals who are planning to study in USA , UK, or Europe may
            submit an application for this grant. <br />
            Moreover, the candidate must have a minimum of two years of job
            experience and must take part in an interview.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>
            Common Wealth Scholarship and Fellowship
          </p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>Full or partial funding</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The Commonwealth Scholarship and Fellowship Plan (CSFP) is an
            international scholarship program that aims to promote collaboration
            and educational exchange among Commonwealth countries. It is funded
            by the member governments of the Commonwealth and administered by
            the Commonwealth Scholarship Commission in the United Kingdom (CSC
            UK). The CSFP is open to citizens of Commonwealth countries who have
            completed their undergraduate education and wish to pursue
            postgraduate studies (Master's or Ph.D.) or engage in research in
            the UK. The eligibility criteria may vary for each participating
            country, and applicants are typically required to meet specific
            academic and English language proficiency requirements.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>Full-bright Nehru Scholarship</p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>
            tution fees,living expenses,round-trip, health insurance
          </p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The Fulbright-Nehru Scholarship is a prestigious scholarship program
            jointly funded by the governments of India and the United States. It
            is administered by the United States-India Educational Foundation
            (USIEF) and the Fulbright Commission in the respective countries.
            The scholarship aims to promote mutual understanding and cultural
            exchange between India and the United States through academic and
            professional exchange programs.The Fulbright-Nehru Scholarships are
            open to Indian citizens who have completed a bachelor's degree or
            equivalent and have relevant work experience in their respective
            fields. The eligibility criteria may vary depending on the specific
            scholarship category, such as student, scholar, or professional.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>
            Swarnajayanti Fellowship for Young Scientists of National Science
            Academy
          </p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>INR 25,000 per month</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The Swarnajayanti Fellowship for Young Scientists is a prestigious
            fellowship program offered by the National Science Academy (INSA) in
            India. The fellowship aims to recognize and support outstanding
            young scientists in the country, providing them with financial
            assistance and research opportunities to pursue their scientific
            careers. The fellowship is open to young scientists in India who
            have demonstrated exceptional potential and achievements in
            scientific research. Typically, candidates should be below the age
            of 40 years and must hold a regular scientific position in a
            recognized institution or university in India.
          </p>
        </div>
        <hr />
        <div className={styles.info}>
          <p className={styles.title}>
            Indian National Olympiad (INO) in Biology
          </p>
          <p className={styles.amount}>Amount/Benefits:</p>
          <p className={styles.descTxt}>None</p>
          <p className={styles.desc}>Description:</p>
          <p className={styles.descTxt}>
            The Indian National Olympiad (INO) in Biology is a competitive
            examination conducted by the Homi Bhabha Centre for Science
            Education (HBCSE) under the aegis of the National Board for Higher
            Mathematics (NBHM) and the Department of Atomic Energy (DAE),
            Government of India. It is one of the stages in the selection
            process for the Indian team to participate in the International
            Biology Olympiad (IBO).The INO is open to Indian students who are in
            their 12th standard or lower and are below the age of 20 as of July
            1st of the respective year. Students must also meet additional
            eligibility criteria set by HBCSE, such as Indian nationality and
            academic performance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Scholarships;
