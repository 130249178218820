const StudentsMenu = [
  // {
  //   title: "Resources",
  //   path: "/Resources",
  // },
  {
    title: "Explore Labs",
    path: "/labs",
  },
  {
    title: "Projects",
    path: "/projects",
  },
  {
    title: "Scholarships",
    path: "/scholarships",
  },
  {
    title: "Competitions",
    path: "/competitions",
  },
  {
    title: "Events",
    path: "/events",
  },
  {
    title: "Gallery",
    path: "/gallery",
  },
  // {
  //   title: "Alumni resources",
  //   path: "/AlumniResources",
  // },
  // {
  //   title: "Blogs",
  //   path: "/Blogs",
  // },
];

const CompaniesMenu = [
  // {
  //   title: "Achievements",
  //   path: "/student-achievements",
  // },
  {
    title: "Brochure",
    path: "https://www.linkedin.com/feed/update/urn:li:activity:7121785770415128576/",
  },
  // {
  //   title: "Collaborations",
  //   path: "/Collaborations",
  // },
  {
    title:"Our Students",
    path:"/our-students"
  },
  // ,
  // {
  //   title: "Ongoing Projects",
  //   path: "/OngoingProjects",
  // },
  {
    title:"Engage With Us",
    path:"/engage-with-us"
  }
];

const FacultyMenu = [
  {
    title: "Achievements",
    path: "/prof-achievements",
  },
  // {
  //   title: "Projects Offerings",
  //   path: "/ProjectOfferings",
  // },
];

const AlumniMenu = [
  // {
  //   title: "Subscription",
  //   path: "/Subscription",
  // },
  {
    title: "Call for talks",
    path: "/call-for-talks",
  },

  // {
  //   title: "Farewell Diary",
  //   path: "/FarewellDiary",
  // },
  // {
  //   title: "Alumni Network",
  //   path: "/AlumniNetwork",
  // },
  // {
  //   title: "Blogs",
  //   path: "/Blogs",
  // },
];

export { StudentsMenu, CompaniesMenu, FacultyMenu, AlumniMenu };
